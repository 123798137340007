import {
    List,
    Datagrid,
    TextField,
    NumberField,
    NumberInput,
    SimpleForm,
    Create,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    Edit,
    SimpleShowLayout,
    usePermissions,
    EditButton,
    useRecordContext,
    useGetOne
} from "react-admin";
import { BackButton } from './BackButton';
import { DepartmentPlusSiteField, TraineeEditToolbar } from "./Trainee"
import {StarRatingInput} from "./StarRating"

export const SeatsShow = (props) => {
    return (
        <Show {...props} >
            <SimpleShowLayout>
                {/* <TextField source="id" /> */}
                <ReferenceField source="rotationID" reference="rotations" label="Rotation" />
                <ReferenceField source="departmentID" reference="departments" label="Department" />
                <NumberField source="preferencePriority" />
            </SimpleShowLayout>
        </Show>)
};


export const SeatCreate = (props) => (
    <Create {...props} actions={<BackButton />}>
        <SimpleForm>
            <ReferenceInput label="Department" source="departmentID" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }} perPage={1000}>
                    <SelectInput label="Department" optionText={record => <DepartmentPlusSiteField record={record} />} />
                </ReferenceInput>
            <NumberInput source="preferencePriority" defaultValue="1" />
            <ReferenceInput source="traineeID" reference="trainees" perPage={1000} />
            <ReferenceInput source="rotationID" reference="rotations" />
        </SimpleForm>
    </Create>
);

export const SeatList = (props) => {
    return (
        <List {...props}>
            <Datagrid>
                <ReferenceField source="departmentID" reference="departments" label="Department">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="rotationID" reference="rotations"/>
                <TraineeNameField/>
                <NumberField source="preferencePriority" />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export const TraineeNameField = (props) =>
{
    const record = useRecordContext(props);

    
    var id = record.traineeID
    if (!(id?.includes("::"))) {
        id = id + "::" + id
    }
    const {data, isLoading} = useGetOne("trainees", {id})
    if (isLoading) {
        return (<></>)
    }
    return (<div>{data?.name}</div>)
}


export const SeatEdit = (props) => {
    const { permissions } = usePermissions();
    return (
        <Edit {...props} actions={<BackButton />}>
            <SimpleForm>
                <ReferenceInput label="Department" source="departmentID" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }} perPage={1000}>
                    <SelectInput label="Department" optionText={record => <DepartmentPlusSiteField record={record} />} />
                </ReferenceInput>
                {permissions.includes("SuperAdmins") && <NumberInput source="preferencePriority" defaultValue="1" />}
            </SimpleForm>
        </Edit>)
};

export const UserSeatEdit = (props) => {
    return (
        <Edit {...props} actions={<BackButton />} redirect={"/"} >
            <SimpleForm  toolbar={<TraineeEditToolbar />} validate={validateSeatEdit}>
                <StarRatingInput source="review" name="review"/>
            </SimpleForm>
        </Edit>)
};

const validateSeatEdit = (values) => {
    const errors = {};
    console.warn("values", values)
    if (!values.review) {
        errors['review'] = "Cannot rate 0 stars"
    }
    return errors
}