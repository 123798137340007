import '@aws-amplify/ui-react/styles.css';
import { TrueIcon, FalseIcon } from './icons';
import dompurify from 'dompurify';
import {
    Show,
    SimpleShowLayout,
    ReferenceInput,
    SelectInput,
    useGetOne,
    BooleanField,
    ReferenceManyField,
    ReferenceField,
    List,
    Datagrid,
    TextField,
    NumberField,
    NumberInput,
    TextInput, FunctionField,
    SimpleForm,
    Create,
    Edit,
    EditButton,
    Labeled,
    ShowButton,
    usePermissions,
    useRecordContext,
    Toolbar,
    useListContext,
    WrapperField,
    RichTextField,
    BooleanInput,
    useRedirect,
    useGetList,
    SimpleList,
    useGetIdentity,
    InfiniteList,
    Form,
    Button,
    useStore
} from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';
import { Typography, Chip, TableCell, TableRow, TableHead, useMediaQuery, Box } from '@mui/material';
import { PostPagination, TraineeEditToolbar, DepartmentPlusSiteField } from './Trainee';
import { BackButton } from './BackButton';
import { is_preference_white_text, get_color_code } from './variable_preferences';

export const DepartmentListHeader = ({ permissions, totalSeatsAvailable, numberOfSeatsReservedForFirstYears, numberOfSeatsReservedForSecondYears }) => (
    <TableHead >
        <TableRow>
            {permissions?.includes("SuperAdmins") && <TableCell ></TableCell>}
            <TableCell key={"name"}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{"Department"}</Typography>
            </TableCell>
            <TableCell key={"group"}>
                <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >Group</Typography>
                </TableCell>
            {process.env.REACT_APP_MULTISITE == "1" &&
                <TableCell key={"site"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Office"}</Typography>
                </TableCell>}
                
            {(permissions?.includes("Admins") || process.env.REACT_APP_DISPLAY_SEATS_AVAILABLE == "1") && <TableCell key={"numberOfSeatsAvailable"}>
                <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Seats available"}</Typography>
                <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Total: " + totalSeatsAvailable} </Typography>
            </TableCell>}
            {permissions?.includes("Admins") &&
                <TableCell key={"numberOfSeatsReservedForFirstYears"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Seats reserved for first years"}</Typography>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Total: " + numberOfSeatsReservedForFirstYears} </Typography>
                </TableCell>}
            {permissions?.includes("Admins") &&
                <TableCell key={"numberOfSeatsReservedForSecondYears"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Seats reserved for second years"}</Typography>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Total: " + numberOfSeatsReservedForSecondYears} </Typography>
                </TableCell>}
            {permissions?.includes("Admins") &&
                <TableCell key={"mustBeFilled"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Must be filled"}</Typography>
                </TableCell>}
            {permissions?.includes("Admins") &&
                <TableCell key={"visibleToTrainees"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Visible to trainees"}</Typography>
                </TableCell>}
            {permissions?.includes("Admins") &&process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME != "0" && <TableCell key={"litigationDepartment"}>
                <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{permissions?.includes("Admins") && process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME}{!permissions?.includes("Admins") && "Counts as " + process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME + " Department"}</Typography>
            </TableCell>}
            {permissions?.includes("Admins") &&process.env.REACT_APP_BANKING_DEPARTMENT_NAME != "0" && <TableCell key={"bankingDepartment"}>
                <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{permissions?.includes("Admins") && process.env.REACT_APP_BANKING_DEPARTMENT_NAME}{!permissions?.includes("Admins") && "Counts as " + process.env.REACT_APP_BANKING_DEPARTMENT_NAME + " Department"}</Typography>
            </TableCell>}
            <TableCell >

            </TableCell>
            <TableCell >

            </TableCell>
        </TableRow >
    </TableHead >
)

export const DepartmentFilterForm = ({ site, setSite }) => {
    const onSubmit = (values) => {
        if (Object.keys(values).length > 0) {
            setSite(values.siteID);
        }
    };
    return (
        <Toolbar sx={{ p: 0 }}>
            <Form onSubmit={onSubmit} defaultValues={{ siteID: site }}  >

                <Box display="flex" alignItems="flex-end" mb={1} sx={{ flexGrow: 1 }}>
                    <Box component="span" mr={2} >
                        <ReferenceInput source="siteID" reference='sites' helperText={false} >
                            <SelectInput source="name" label="Site" helperText={false} />
                        </ReferenceInput>
                    </Box>
                    <Box component="span" mr={2} mb={1}>
                        <Button size='large' variant="outlined" color="primary" type="submit">
                            Go
                        </Button>
                    </Box>
                </Box>
            </Form>
        </Toolbar >
    )
}

export const AdminDepartmentsList = (props) => {
    const { permissions } = usePermissions();

    const [site, setSite] = useStore('department.site', "");
    var filter = { departmentsByTypeAndName: { type: "Department" } }

    if (site) {
        filter = { departmentsByTypeAndName: { type: "Department", filter: { siteID: { eq: site } } } }
    }
    const departmentsList = useGetList('departments', { filter: filter, pagination: { page: 1, perPage: 1000 } });
    var totalSeatsAvailable = 0;
    var numberOfSeatsReservedForFirstYears = 0
    var numberOfSeatsReservedForSecondYears = 0
    for (const department in departmentsList.data) {
        totalSeatsAvailable += departmentsList.data[department].numberOfSeatsAvailable
        numberOfSeatsReservedForFirstYears += departmentsList.data[department].numberOfSeatsReservedForFirstYears
        numberOfSeatsReservedForSecondYears += departmentsList.data[department].numberOfSeatsReservedForSecondYears
    }

    if (!departmentsList.isLoading) {
        return (
            <List {...props} exporter={false} pagination={false} sort={{ field: 'name', order: 'DESC' }} filter={filter} perPage={1000}>
                <DepartmentFilterForm site={site} setSite={setSite} />

                <Datagrid header={<DepartmentListHeader permissions={permissions} totalSeatsAvailable={totalSeatsAvailable} numberOfSeatsReservedForFirstYears={numberOfSeatsReservedForFirstYears} numberOfSeatsReservedForSecondYears={numberOfSeatsReservedForSecondYears} />} bulkActionButtons={permissions?.includes("SuperAdmins") ? true : false} sx={{
                    '& .column-name': { textAlign: 'left' },
                }}>
                    <TextField source="name" sortBy="departmentsByName" sortable={false} />
                    <ReferenceField source="groupID" reference="groups" label="Group" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                    {process.env.REACT_APP_MULTISITE == "1" &&
                        <ReferenceField source="siteID" reference="sites" label="Office" link={false}>
                            <TextField source="name" />
                        </ReferenceField>}
                    {(permissions?.includes("Admins") || process.env.REACT_APP_DISPLAY_SEATS_AVAILABLE == "1") && <NumberField source="numberOfSeatsAvailable" sortable={false} />}
                    {permissions?.includes("Admins") && <NumberField source="numberOfSeatsReservedForFirstYears" sortable={false} />}
                    {permissions?.includes("Admins") && <NumberField source="numberOfSeatsReservedForSecondYears" sortable={false} />}
                    {permissions?.includes("Admins") && (<BooleanField label="Must be filled" source="mustBeFilled" sortable={false} defaultValue={false} TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>)}
                    {permissions?.includes("Admins") && <BooleanField source="visibleToTrainees" TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>}
                    {process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME != "0" && <BooleanField label={process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME + " Department"} source="litigationDepartment" sortable={false}TrueIcon={TrueIcon} FalseIcon={FalseIcon} />}
                    {process.env.REACT_APP_BANKING_DEPARTMENT_NAME != "0" && <BooleanField label={process.env.REACT_APP_BANKING_DEPARTMENT_NAME + " Department"} source="bankingDepartment" sortable={false} TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>}
                    {permissions?.includes("Admins") && (<EditButton />)}
                    <WrapperField><ShowButton label="More details" /></WrapperField>
                </Datagrid>
            </List>
        );
    }

};

export const TraineeDepartmentsList = (props) => {
    const { permissions } = usePermissions();
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    );
    const { data, isLoading } = useGetIdentity();
    const traineeData = useGetOne('trainees', { id: data?.id + "::" + data?.id })
    const sites = useGetList("sites");

    var wildcard_site_id = ""
    var twin_site_id = ""
    for (const site in sites?.data) {
        console.log(sites?.data[site])
        if (sites?.data[site].wildcard) {
            console.log(sites?.data[site].wildcard)
            wildcard_site_id = sites?.data[site].id
        }
        if (sites?.data[site].id == traineeData.data?.siteID) {
            twin_site_id = sites?.data[site].twinSiteID
        }
    }
    var filter = { departmentsBySiteIDAndName: { siteID: traineeData.data?.siteID, visibleToTrainees: { ne: false } } };
    if (wildcard_site_id && twin_site_id) {
        filter = {
            departmentsByTypeAndName: { type: "Department", filter: { or: [{ siteID: { eq: traineeData.data?.siteID } }, { siteID: { eq: wildcard_site_id } }, { siteID: { eq: twin_site_id } }], visibleToTrainees: { ne: false } } }
        };
    } else if (wildcard_site_id) {
        filter = {
            departmentsByTypeAndName: { type: "Department", filter: { or: [{ siteID: { eq: traineeData.data?.siteID } }, { siteID: { eq: wildcard_site_id } }], visibleToTrainees: { ne: false } } }
        };
    } else if (twin_site_id) {
        filter = {
            departmentsByTypeAndName: { type: "Department", filter: { or: [{ siteID: { eq: traineeData.data?.siteID } }, { siteID: { eq: twin_site_id } }], visibleToTrainees: { ne: false } } }
        };
    }

    if (isSmall) {
        return (<Labeled label="Departments">
            <InfiniteList {...props} exporter={false} sort={{ field: 'name', order: 'DESC' }} filter={filter} actions={false}>
                <SimpleList primaryText={record => record.name} secondaryText={record => { if (permissions?.includes("Admins") || process.env.REACT_APP_DISPLAY_SEATS_AVAILABLE == "1") { return (record.numberOfSeatsAvailable != 1 ? record.numberOfSeatsAvailable + " seats available" : "1 seat available") } else { return "" } }} linkType="show" />
            </InfiniteList></Labeled>)
    } else {
        return (

            <List {...props} exporter={false} pagination={<PostPagination />} sort={{ field: 'name', order: 'DESC' }} filter={filter} perPage={100}>
                <Datagrid header={<DepartmentListHeader permissions={permissions} totalSeatsAvailable={0} numberOfSeatsReservedForFirstYears={0} numberOfSeatsReservedForSecondYears={0} />} bulkActionButtons={permissions?.includes("SuperAdmins") ? true : false} sx={{
                    '& .column-name': { textAlign: 'left' },
                }}>
                    <TextField source="name" sortBy="departmentsByName" sortable={false} />
                    <ReferenceField source="groupID" reference="groups" label="Group" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                    {process.env.REACT_APP_MULTISITE == "1" &&
                        <ReferenceField source="siteID" reference="sites" label="Office" link={false}>
                            <TextField source="name" />
                        </ReferenceField>}
                    {permissions?.includes("Admins") && process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME != "0" && <BooleanField label={process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME + " Department"} source="litigationDepartment" sortable={false} TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>}
                    {permissions?.includes("Admins") && process.env.REACT_APP_BANKING_DEPARTMENT_NAME != "0" && <BooleanField label={process.env.REACT_APP_BANKING_DEPARTMENT_NAME + " Department"} source="bankingDepartment" sortable={false} TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>}
                    {permissions?.includes("Admins") && (<EditButton />)}
                    <WrapperField><ShowButton label="More details" /></WrapperField>
                </Datagrid>
            </List>
        );
    }

};

const validateDepartmentCreate = (values) => {
    const errors = {};
    console.warn("values", values)
    if (!values.siteID) {
        errors['siteID'] = "Must select a site"
    }
    if (!values.name) {
        errors['name'] = "Must enter department name"
    }
    if (!values.numberOfSeatsAvailable < 0) {
        errors['numberOfSeatsAvailable'] = "Cannot have negative number of seats available"
    }
    if (!values.numberOfSeatsReservedForFirstYears < 0) {
        errors['numberOfSeatsReservedForFirstYears'] = "Cannot have negative number of seats reserved"
    }
    if (!values.numberOfSeatsReservedForSecondYears < 0) {
        errors['numberOfSeatsReservedForSecondYears'] = "Cannot have negative number of seats reserved"
    }
    return errors
}

const validateDepartmentEdit = (values) => {
    const errors = {};
    console.warn("values", values)
    if (process.env.REACT_APP_MULTISITE == "1" && !values.siteID) {
        errors['siteID'] = "Must select a site"
    }
    if (!values.name) {
        errors['name'] = "Must enter department name"
    }
    if (!values.numberOfSeatsAvailable < 0) {
        errors['numberOfSeatsAvailable'] = "Cannot have negative number of seats available"
    }
    if (!values.numberOfSeatsReservedForFirstYears < 0) {
        errors['numberOfSeatsReservedForFirstYears'] = "Cannot have negative number of seats reserved"
    }
    if (!values.numberOfSeatsReservedForSecondYears < 0) {
        errors['numberOfSeatsReservedForSecondYears'] = "Cannot have negative number of seats reserved"
    }
    return errors
}



export const DepartmentsCreate = (props) => (
    <Create {...props} actions={<BackButton />} >
        <SimpleForm toolbar={<TraineeEditToolbar />} defaultValues={{ type: "Department" }} validate={validateDepartmentCreate} sanitizeEmptyValues={false}>
            <ReferenceInput source="siteID" reference="sites" label="Office" >
                <SelectInput label="Office" />
            </ReferenceInput>
            <ReferenceInput source="groupID" reference="groups" label="Group" >
                    <SelectInput label="Group" />
                </ReferenceInput>
            <TextInput source="name" />
            <BooleanInput label="Must be filled" source="mustBeFilled" />
            <BooleanInput source="visibleToTrainees" defaultValue={true} />
            {process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME != "0" && <BooleanInput label={process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME + " Department"} source="litigationDepartment" />}
            {process.env.REACT_APP_BANKING_DEPARTMENT_NAME != "0" && <BooleanInput label={process.env.REACT_APP_BANKING_DEPARTMENT_NAM + " Department"} source="bankingDepartment" />}
            <NumberInput source="numberOfSeatsAvailable" defaultValue={0} />
            <NumberInput source="numberOfSeatsReservedForFirstYears" defaultValue={0} />
            <NumberInput source="numberOfSeatsReservedForSecondYears" defaultValue={0} />
            <ReferenceInput source="twinDepartmentID" allowEmpty reference='departments' label="Twinned Department" perPage={1000} filter={{departmentsByTypeAndName: {type: "Department"}}}>
                <SelectInput label="Twinned Department" optionText={record => <DepartmentPlusSiteField record={record} />}/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export const DepartmentsEdit = (props) => {
    const { permissions } = usePermissions();

    return(
    <Edit {...props} actions={<BackButton />} transform={value => { return value; }} >
        <SimpleForm toolbar={<TraineeEditToolbar />} validate={validateDepartmentEdit} sanitizeEmptyValues={false}>
            {process.env.REACT_APP_MULTISITE == "1" &&
                <ReferenceInput source="siteID" reference="sites" label="Office" >
                    <SelectInput label="Office" />
                </ReferenceInput>}
                <ReferenceInput source="groupID" reference="groups" label="Group" >
                    <SelectInput label="Group" />
                </ReferenceInput>
            <TextInput source="name" />
            <BooleanInput label="Must be filled" source="mustBeFilled" />
            <NumberInput source="numberOfSeatsAvailable" />
            <NumberInput source="numberOfSeatsReservedForFirstYears" />
            <NumberInput source="numberOfSeatsReservedForSecondYears" />
            {process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME != "0" && <BooleanInput label={process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME + " Department"} source="litigationDepartment" />}
            {process.env.REACT_APP_BANKING_DEPARTMENT_NAME != "0" && <BooleanInput label={process.env.REACT_APP_BANKING_DEPARTMENT_NAME + " Department"} source="bankingDepartment" />}
            <BooleanInput source="visibleToTrainees" />
            {permissions?.includes("SuperAdmins") && <BooleanInput source="notDecidedIndicator" />}
            <ReferenceInput source="twinDepartmentID" parse={value => { return value; }} allowEmpty reference='departments' label="Twinned Department" perPage={1000} filter={{departmentsByTypeAndName: {type: "Department"}}}>
                <SelectInput label="Twinned Department" parse={value => { return value; }} allowEmpty  optionText={record => <DepartmentPlusSiteField record={record} />}/>
            </ReferenceInput>
            <RichTextInput source="departmentInfo" label="Department Information:" />
        </SimpleForm>
    </Edit>)
};

export const DepartmentShow = (props) => {
    const { permissions } = usePermissions();
    return (
        <Show {...props} actions={<BackButton />}>
            <SimpleShowLayout>
                <TextField source="name" label="Name" />
                {process.env.REACT_APP_MULTISITE == "1" &&
                    <ReferenceField source="siteID" reference="sites" label="Office" link={false}>
                        <TextField source="name" />
                    </ReferenceField>}
                    <ReferenceField source="groupID" reference="groups" label="Group" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                {permissions?.includes("Admins") && <BooleanField source="visibleToTrainees" TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>}
                {(permissions?.includes("Admins") || process.env.REACT_APP_DISPLAY_SEATS_AVAILABLE == "1") && < NumberField source="numberOfSeatsAvailable" />}
                {permissions?.includes("Admins") && process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME != "0" && <BooleanField label={"Counts as a " + process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME + " Department"} source="litigationDepartment" sortable={false} TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>}
                {permissions?.includes("Admins") && process.env.REACT_APP_BANKING_DEPARTMENT_NAME != "0" && <BooleanField label={"Counts as a " + process.env.REACT_APP_BANKING_DEPARTMENT_NAME + " Department"} source="bankingDepartment" TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>}
                {permissions?.includes("Admins") &&
                    <DepartmentSeatsList />}
                <TargetBlankEnabledRichTextField source="departmentInfo" label="Department Information" />
            </SimpleShowLayout>
        </Show>
    )
}

const TargetBlankEnabledRichTextField = (props) => {
    dompurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });
    return <RichTextField {...props} />;
};


const DepartmentSeatsFilter = [
    <ReferenceInput label="Rotation Date" reference="rotations" source="seatsByRotationIDAndDepartmentID.rotationID" alwaysOn defaultValue={""} filter={{ rotationsByTypeAndStartDateAdmin: {type:"Rotation", filter: { visible: {eq: true}} }}} >
        <SelectInput label="Rotation" />
    </ReferenceInput>
];

const TraineeByPriorityField = () => {
        const record = useRecordContext();
    const id = record.traineeID.split("::")[0] + "::" + record.traineeID.split("::")[0];
    const redirect = useRedirect();
    const { isLoading, data } = useGetOne('trainees', { id });
    const { filterValues } = useListContext();

    console.log('seat', data)
    var changeTextColour = false
    var priority = process.env.REACT_APP_NUM_OF_PREFRENCES + 1;
    for (const preference in data?.Preferences?.items) {
        if ((data['Preferences']['items'][preference]['departmentID'] == record.departmentID) &&
            (data['Preferences']['items'][preference]['rotationID'] == filterValues['seatsByRotationIDAndDepartmentID']['rotationID'])) {
            priority = data['Preferences']['items'][preference]['priority']
        }
    }
    if (priority) {
        if (priority > process.env.REACT_APP_NUM_OF_PREFRENCES) {
            priority = parseInt(process.env.REACT_APP_NUM_OF_PREFRENCES) + 1
        }
        const text_color = is_preference_white_text(priority) ? 'white' : 'black'

        if (changeTextColour) {
            return (<Chip label={data?.name} sx={{ backgroundColor: get_color_code(priority), '& .MuiChip-label': { color: text_color } }} onClick={() => redirect(`/trainees/${data.id}/show`)} clickable={true} />)
        } else {
            return (<Chip label={data?.name} sx={{ backgroundColor: get_color_code(priority), '& .MuiChip-label': { color: text_color } }} onClick={() => redirect(`/trainees/${data.id}/show`)} clickable={true} />)
        }
    } else {
        return (
            <Chip label={data?.name} onClick={() => redirect(`/trainees/${data.id}/show`)} clickable={true} />
        )
    }
    
}

export const DepartmentSeatsList = () => {
        const record = useRecordContext();
        return (
        <Labeled label="Assigned Trainees">
            <List resource="seats" filters={DepartmentSeatsFilter} actions={false} pagination={<PostPagination />} queryOptions={{ meta: { seatsByRotationIDAndDepartmentID: { departmentID: { "eq": record.id } } } }} empty={false} sx={{ width: "150px" }}>
                <Datagrid bulkActionButtons={false}  >
                    <TraineeByPriorityField />
                </Datagrid>
            </List>
        </Labeled>
    );
}