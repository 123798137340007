import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,Legend } from 'recharts';
import { Filter,required,WithListContext, useGetList, ReferenceInput, SelectInput, List, Datagrid, NumberField, FunctionField, TextField, useStore,
    FilterList,useRefresh, useNotify,
    FilterListItem } from "react-admin";
import { Card, CardContent } from '@mui/material';

import { PostPagination } from './Trainee';
import { indexToYear, formatDate} from './Rotation'
import { wordy_ordinal, get_color_code } from './variable_preferences';

const preferencesKeys = (priority) => {
                                        var wordy = wordy_ordinal(Number(priority));
                                        wordy = wordy.charAt(0).toUpperCase() + wordy.slice(1);;
                                        return "numberOf" + wordy + "Preferences"
                                    }
const preferencesNames = (priority) =>   {
    var wordy = wordy_ordinal(Number(priority));
    wordy = wordy.charAt(0).toUpperCase() + wordy.slice(1);;
    return  wordy + " Preferences"
}


export const Analytics = (props) => {
    return (<><DepartmentStatisticsCharts/></>)
}

const DepartmentStatisticsFilter = (props) => {
    const { isLoading, data } = useGetList('rotations');
    data?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));
    const currentCohorts = data?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible));
    const currentRotation = currentCohorts?.at(-1)
    return (
        <Filter {...props}>
        <ReferenceInput label="Rotation" reference="rotations" filter={{ rotationsByTypeAndStartDate: { type: "Rotation" } }} source="rotationID" alwaysOn>
            <SelectInput label="Rotation Date" defaultValue={currentRotation?.id} validate={required()}/>
        </ReferenceInput>
    </Filter>)
};


export const DepartmentStatisticsCharts= () => {
        const { isLoading, data } = useGetList('rotations');
        data?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));
        const currentCohorts = data?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible));
        const currentRotation = currentCohorts?.at(-1)
        return (
            <List resource="metrics" filter={{ queryName: 'metricsByTypeAndDepartmentName', queryVariables: {type: "Metric"}}} filterDefaultValues={{rotationID: {eq: currentRotation?.id}}} 
            aside={<FilterSidebar />}
            actions={false} empty={false} perPage={10000}pagination={false}>
                        <WithListContext
                    render={({ data }) => {
                        const preference_data = get_preference_breakdown(data)
                        var preference_bars = []
                        for (let priority = 1; priority <= process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
                            preference_bars.push(<Bar dataKey={preferencesKeys(priority)} stackId="a" fill={get_color_code(priority)} name={preferencesNames(priority)}/>)
                        }
                        var assigned_preference_bars = []
                        for (let priority = 1; priority <= process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
                            assigned_preference_bars.push(<Bar dataKey={preferencesKeys(priority) + "Assigned"} stackId="b" fill={get_color_code(priority)} name={preferencesNames(priority)+ " Assigned"}/>)
                        }
                        return (
                        <BarChart width={1500} height={700} data={preference_data} margin={{
                            top: 5,
                            right: 30,
                            left: 150,
                            bottom: 300,
                          }}>
                            <XAxis dataKey="departmentName" angle={-45} textAnchor="end" interval={0}/>
                            <YAxis />
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            <Tooltip />
                            <Legend verticalAlign="top"/>
                            {preference_bars}
                            {assigned_preference_bars}
                            <Bar dataKey="numberOfNoPreferencesAssigned" stackId="b" fill="black" name="No Preferences Assigned"/> 

                        </BarChart>
                )}}
                />
                <WithListContext
                    render={({ data }) => {
                        const cohortData = get_cohort_breakdown(data)
                        return(
                        <BarChart width={1500} height={700} data={cohortData} margin={{
                            top: 5,
                            right: 30,
                            left: 150,
                            bottom: 300,
                          }}>
                            <XAxis dataKey="departmentName" angle={-45} textAnchor="end" interval={0}/>
                            <YAxis />
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            <Tooltip />
                            <Legend verticalAlign="top"/>
                            <Bar dataKey="First Seat" stackId="a" fill="cyan" name="First Seats Assigned"/>
                            <Bar dataKey="Second Seat" stackId="a" fill="pink" name="Second Seats Assigned"/>
                            <Bar dataKey="Third Seat" stackId="a" fill="magenta" name="Third Seats Assigned"/>
                            <Bar dataKey="Fourth Seat" stackId="a" fill="purple" name="Fourth Seats Assigned"/>

                        </BarChart>
        )}}
                />
            </List>
        );
}

const get_preference_breakdown = (data) => {
    var preference_dict = {}
    for (let metricIndex in data) {
        const key = JSON.stringify([data[metricIndex]['departmentID'], data[metricIndex]['departmentName'], data[metricIndex]['siteName']])
        if (key in preference_dict) {
            preference_dict[key][Number(data[metricIndex]['assigned'])][data[metricIndex]['preferencePriority']-1] += data[metricIndex]['metricCount']
        } else {
            preference_dict[key] = [Array(Number(process.env.REACT_APP_NUM_OF_PREFRENCES)).fill(0),Array(Number(process.env.REACT_APP_NUM_OF_PREFRENCES+1)).fill(0)]
            preference_dict[key][Number(data[metricIndex]['assigned'])][data[metricIndex]['preferencePriority']-1] = data[metricIndex]['metricCount']
        }
    }

    var preference_data = []
    for (const key in preference_dict) {
        const keyArray = JSON.parse(key)
        var departmentName =  keyArray[1]
        if (process.env.REACT_APP_MULTISITE == "1") {
            departmentName = departmentName + " - " + keyArray[2]
        }
        var preference_data_item = { 'departmentID': keyArray[0],
                                        'departmentName': departmentName}
        for (let priority = 1; priority <= process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
            preference_data_item[preferencesKeys(priority)] =  preference_dict[key][0][priority-1]
            preference_data_item[preferencesKeys(priority)+"Assigned"] =  preference_dict[key][1][priority-1]
        }
        preference_data_item["numberOfNoPreferencesAssigned"] = preference_dict[key][1][Number(process.env.REACT_APP_NUM_OF_PREFRENCES)]
        preference_data.push(preference_data_item)
    }
    return preference_data
}

const get_cohort_breakdown = (data) => {

    var cohort_dict = {}
    for (let metricIndex in data) {
        if (data[metricIndex]['assigned']) {
            const key = JSON.stringify([data[metricIndex]['departmentID'], data[metricIndex]['departmentName'], data[metricIndex]['siteName']])
            if (key in cohort_dict) {
                cohort_dict[key][data[metricIndex]['cohortIndex']] += data[metricIndex]['metricCount']
            } else {
                cohort_dict[key] = Array(12).fill(0)
                cohort_dict[key][data[metricIndex]['cohortIndex']] = data[metricIndex]['metricCount']
            }
        }
    }

    var cohort_data = []
    for (const key in cohort_dict) {
        const keyArray = JSON.parse(key)
        var departmentName =  keyArray[1]
        if (process.env.REACT_APP_MULTISITE == "1") {
            departmentName = departmentName + " - " + keyArray[2]
        }
        var cohort_data_item = { 'departmentID': keyArray[0],
                                'departmentName': departmentName}
        for (let cohortIndex = 0; cohortIndex <= 12; cohortIndex++) {
            cohort_data_item[indexToYear(cohortIndex)] = cohort_dict[key][cohortIndex]
        }
        cohort_data.push(cohort_data_item)
    }

    return cohort_data
}


export const FilterSidebar = ({nontransparentDepartmentID, nontransparentGroupID, setDepartmentID, setGroupID}) => {
   
    const isSelectedSite = (value, filters) => {
        const sites = filters.or || [];
        return sites.some(e => e.siteID.eq === value.siteID.eq);
    };

    const toggleFilterSite = (value, filters) => {
        const sites = filters.or || [];
        return {
            ...filters,
            or: sites.some(e => e.siteID.eq === value.siteID.eq)
                // Remove the category if it was already present
                ? sites.filter(v => v.siteID.eq !== value.siteID.eq)
                // Add the category if it wasn't already present
                : [...sites, value],
        };
    };

    const isSelectedCohort = (value, filters) => {
        const cohorts = filters.and ? filters.and[0].or || [] : [];
        console.log(cohorts, "cohorts", value)

        return cohorts.some(e => e?.cohortID.eq === value[0]?.cohortID.eq);
    }

    const toggleFilterCohort = (value, filters) => {
        var cohorts = filters.and ? filters.and[0].or || [] : [];
        for (let cohortCounter = 0; cohortCounter < value.length; cohortCounter++) {
            if (cohorts.some(e => e.cohortID.eq == value[cohortCounter].cohortID.eq)){
                cohorts = cohorts.filter(v => v.cohortID.eq !== value[cohortCounter].cohortID.eq)
            } else {
                cohorts = [...cohorts, value[cohortCounter]]
            }
        }
        if (cohorts.length == 0) {
            delete filters.and
            return filters
        }
        return {
            ...filters,
            and: [{
                or: cohorts,
            }]
        };
    };


    const sites = useGetList("sites");
    var sitesListItems = []
    for (let siteIndex = 0; siteIndex < sites.data?.length; siteIndex++) {
        sitesListItems.push(<FilterListItem label={sites.data[siteIndex].name} value={{ siteID: {eq: sites.data[siteIndex].id}}} isSelected={isSelectedSite} toggleFilter={toggleFilterSite} key={"siteListItems"+ siteIndex}/>
        )
    }

    const { data: rotations } = useGetList('rotations');
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));
    const currentCohorts = rotations?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible));
    var rotationListItems = []
    for (let rotationIndex = 0; rotationIndex < rotations?.length; rotationIndex++) {
        rotationListItems.push(
            <FilterListItem label={formatDate(rotations[rotationIndex].startDate)} value={{rotationID: {eq: rotations[rotationIndex].id}}} />
            )
    }


    return (
        <Card sx={{ order: -1, mr: 2, mt: 9, width: 250, height: "max-content" }}>
        <CardContent>
            <FilterList label="Sites" >
                {sitesListItems}
            </FilterList>
            <FilterList label="Rotations" >
                {rotationListItems}  
            </FilterList>
        </CardContent>
    </Card>
    )
}