import { Create,Datagrid,Edit, List, NumberField, NumberInput, ReferenceField, ReferenceInput, SelectInput, SimpleForm } from "react-admin";


export const CohortRotationCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="rotationID" reference="rotations"> 
                <SelectInput label="Rotation"/>
            </ReferenceInput>
            <ReferenceInput source="cohortID" reference="rotations"> 
                <SelectInput label="Cohort"/>
            </ReferenceInput>
            <NumberInput source="cohortsIndex"/>
        </SimpleForm>
    </Create>
)

export const CohortRotationEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput source="rotationID" reference="rotations"> 
                <SelectInput label="Rotation"/>
            </ReferenceInput>
            <ReferenceInput source="cohortID" reference="rotations"> 
                <SelectInput label="Cohort"/>
            </ReferenceInput>
            <NumberInput source="cohortsIndex"/>
        </SimpleForm>
    </Edit>
)

export const CohortRotationList = (props) => (
    <List {...props}>
        <Datagrid>
            <ReferenceField source="rotationID" reference="rotations"/>
            <ReferenceField source="cohortID" reference="rotations"/>
            <NumberField source="cohortsIndex"/>
        </Datagrid>
    </List>
)