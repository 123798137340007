import { List,
    WrapperField, 
    ReferenceField, 
    FunctionField,
    SelectColumnsButton,
    TextField,useRedirect,
    TopToolbar, 
    useGetList,
    DatagridConfigurable,
    ReferenceManyField,useUpdate,
    SingleFieldList,NumberField,
    FilterList,useRefresh, useNotify,
     FilterListItem,useStore,
     usePermissions, CreateButton, Button, ReferenceInput,SelectInput, SimpleForm,
     useRecordContext  } from "react-admin"
import { API, Auth } from 'aws-amplify';
import {useState} from 'react'
import { DataGridWithIndex } from "./DataGridWithIndex"
import { TraineeExportButton} from "./TraineeExportButton"
import { RotationDataField, exporter } from "./RotationFilterForm";
import { Card, CardContent,Slider,Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper, Chip } from '@mui/material';

import { TraineeShowPageLink, ListCommentsField, ManualOverrideField, NamePlusReview, MouseOverPopoverChip,DepartmentPlusSiteField} from "./Trainee"
import {indexToYear, formatDate} from "./Rotation"
import {ordinal_suffix_of, get_color_code,is_preference_white_text} from "./variable_preferences"

import { useFormContext } from 'react-hook-form'



export const TraineeList = (props) => {
    const [nontransparentDepartmentID, setDepartmentID] = useStore('traineelist.departmentID',"")
    const [nontransparentGroupID, setGroupID] = useStore('traineelist.groupID',"")
    const [sidebarOpen, setSidebarOpen] = useStore('traineelist.sidebaropen', false)
    const { isLoading: isLoadingRotations, data: rotations } = useGetList('rotations', { filter: { rotationsByTypeAndStartDateAdmin: { type: "Rotation" } } });
    if (isLoadingRotations) { return (<></>) }

    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));
    const currentCohorts = rotations?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible));
    const currentRotation = currentCohorts?.at(-1)
    const currentRotationID = currentRotation?.id
    var preferenceColumns = []
    for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
        preferenceColumns.push(<ReferenceManyField label={ordinal_suffix_of(priority + 1) + " Preferences"} reference="preferences" target="preferencesByTraineeIDAndRotationID.traineeID.rotationID" filter={{preferencesByTraineeIDAndRotationID: { rotationID: { "eq": currentRotationID } }}} sortable={false} key={"preferenceField" + priority}>
            <SingleFieldList sx={{gap:0,justifyContent: "center"}}>
                <TraineePreferencesListCropped priority={priority + 1} links={false} nontransparentDepartmentID={nontransparentDepartmentID} nontransparentGroupID={nontransparentGroupID}/>
            </SingleFieldList>
        </ReferenceManyField>)
    }
    return (
    <List 
    filter={{ queryName: 'traineesByTypeAndTraineeIndex', queryVariables: {type: 'Trainee'}, not: {not: {or: currentCohorts?.map((rotation) => ({ cohortID: { eq: rotation.id } }))}}} } 
    perPage={10000}
    aside={<FilterSidebar setDepartmentID={setDepartmentID} setGroupID={setGroupID} nontransparentDepartmentID={nontransparentDepartmentID} nontransparentGroupID={nontransparentGroupID} sidebarOpen={sidebarOpen}/>}
    actions={<TraineeListActions currentRotation={currentRotation} isLoading={isLoadingRotations} setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}/>}
    pagination={false}
    >
        {/* <FilterSidebar nontransparentDepartmentID={nontransparentDepartmentID} setDepartmentID={setDepartmentID} nontransparentGroupID={nontransparentGroupID} setGroupID={setGroupID}/> */}
        <DatagridConfigurable bulkActionButtons={false} >
        <WrapperField source="name" sortable={false}>
            <TraineeShowPageLink />
        </WrapperField>
        {process.env.REACT_APP_MULTISITE == "1" &&
        <ReferenceField source="siteID" reference="sites" label="Office" link={false} sortable={false}>
            <TextField source="name" />
        </ReferenceField>}
        <ReferenceField source="cohortID" reference="rotations" label="Cohort" link={false} sortable={false}>
            <FunctionField label="Cohort" render={(record) => indexToYear(record.cohortsIndex)} />
        </ReferenceField>
        <NumberField source="optiseatIndex" label="OptiSeat Index" sortable={false}/>
        <WrapperField label="Manual Index" sortable={false}>
        <ManualIndexField />
        </WrapperField>
        {preferenceColumns}
        <ManualOverrideField {...props} label="Manual Override" sortable={false} />
        <WrapperField label="Comments" sortable={false}>
            <ListCommentsField rotationID={currentRotationID}/>                
        </WrapperField>
        <ReferenceManyField label="Reference Seats" reference='referenceSeats' target='referenceSeatsByTraineeIDAndRotationID.traineeID.rotationID' filter={{referenceSeatsByTraineeIDAndRotationID: { rotationID: { "eq": currentRotationID } }}} sortable={false}>
            <SingleFieldList linkType={false} sx={{justifyContent: "center"}}>
                <SeatFieldCropped nontransparentDepartmentID={nontransparentDepartmentID} nontransparentGroupID={nontransparentGroupID} text_color={"black"}/>
            </SingleFieldList>
        </ReferenceManyField>
        <ReferenceManyField label="Assigned Seats" reference='seats' target='seatsByTraineeIDAndRotationID.traineeID.rotationID' filter={{seatsByTraineeIDAndRotationID: { rotationID: { "eq": currentRotationID } }}} sortable={false}>
            <SingleFieldList linkType={false} sx={{justifyContent: "center"}}>
                <SeatFieldCropped nontransparentDepartmentID={nontransparentDepartmentID} nontransparentGroupID={nontransparentGroupID} text_color={"black"}/>
            </SingleFieldList>
        </ReferenceManyField>
        </DatagridConfigurable>
    </List>)
}

export const ManualIndexField = (props) => {

    const redirect = useRedirect();
    const traineeRecord = useRecordContext();
    const [update, { isLoading, error }] = useUpdate();
    const refresh = useRefresh();


    if (traineeRecord?.manualIndex && traineeRecord?.manualIndex != traineeRecord?.optiseatIndex) {
        return (
            <Button onClick={(e) => {
                e.stopPropagation(); update(
                    'trainees',
                    { id: traineeRecord.id, data: {id: traineeRecord.id, manualIndex: traineeRecord?.optiseatIndex},previousData:traineeRecord}
                ); refresh();
            }}>
                <NumberField source="manualIndex" label="Manual Index" sx={{color:"black", fontSize:"inherit !important"}}/>
            </Button>
            
        )
    } else {
        return (
            <Button onClick={(e) => {
                e.stopPropagation(); redirect('edit', 'trainees', traineeRecord.id, {}, { manualIndex: true });
            }}>
                <AddIcon />
            </Button >
        )
    }
}



export const FilterSidebar = ({nontransparentDepartmentID, nontransparentGroupID, setDepartmentID, setGroupID, sidebarOpen}) => {
   
    const isSelectedSite = (value, filters) => {
        const sites = filters.or || [];
        return sites.some(e => e.siteID.eq === value.siteID.eq);
    };

    const toggleFilterSite = (value, filters) => {
        const sites = filters.or || [];
        return {
            ...filters,
            or: sites.some(e => e.siteID.eq === value.siteID.eq)
                // Remove the category if it was already present
                ? sites.filter(v => v.siteID.eq !== value.siteID.eq)
                // Add the category if it wasn't already present
                : [...sites, value],
        };
    };

    const isSelectedCohort = (value, filters) => {
        const cohorts = filters.and ? filters.and[0].or || [] : [];
        console.log(cohorts, "cohorts", value)

        return cohorts.some(e => e?.cohortID.eq === value[0]?.cohortID.eq);
    }

    const toggleFilterCohort = (value, filters) => {
        var cohorts = filters.and ? filters.and[0].or || [] : [];
        for (let cohortCounter = 0; cohortCounter < value.length; cohortCounter++) {
            if (cohorts.some(e => e.cohortID.eq == value[cohortCounter].cohortID.eq)){
                cohorts = cohorts.filter(v => v.cohortID.eq !== value[cohortCounter].cohortID.eq)
            } else {
                cohorts = [...cohorts, value[cohortCounter]]
            }
        }
        if (cohorts.length == 0) {
            delete filters.and
            return filters
        }
        return {
            ...filters,
            and: [{
                or: cohorts,
            }]
        };
    };


    const sites = useGetList("sites");
    var sitesListItems = []
    for (let siteIndex = 0; siteIndex < sites.data?.length; siteIndex++) {
        sitesListItems.push(<FilterListItem label={sites.data[siteIndex].name} value={{ siteID: {eq: sites.data[siteIndex].id}}} isSelected={isSelectedSite} toggleFilter={toggleFilterSite} key={"siteListItems"+ siteIndex}/>
        )
    }

    const { data: rotations } = useGetList('rotations');
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));
    const currentCohorts = rotations?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible));
    var firstSeatCohorts = []
    var secondSeatCohorts = []
    var thirdSeatCohorts = []
    var fourthSeatCohorts = []
    for (let rotationIndex = 0; rotationIndex < currentCohorts?.length; rotationIndex++) {
        if (rotations[rotationIndex].cohortsIndex == 0){
            firstSeatCohorts.push({cohortID: {eq: rotations[rotationIndex].id}})
        } else if (rotations[rotationIndex].cohortsIndex == 1){
            secondSeatCohorts.push({cohortID: {eq: rotations[rotationIndex].id}})
        } else if (rotations[rotationIndex].cohortsIndex == 2){
            thirdSeatCohorts.push({cohortID: {eq: rotations[rotationIndex].id}})
        } else if (rotations[rotationIndex].cohortsIndex == 3){
            fourthSeatCohorts.push({cohortID: {eq: rotations[rotationIndex].id}})
        }
    }
    if (sidebarOpen) {
    return (
        <Card sx={{ order: -1, mr: 2, mt: 9, width: 250, height: "max-content"}}>
        <CardContent>
            <FilterList label="Sites" >
                {sitesListItems}
            </FilterList>
            <FilterList label="Cohorts" >
                <FilterListItem label="First Seat" value={firstSeatCohorts} isSelected={isSelectedCohort} toggleFilter={toggleFilterCohort}/>
                <FilterListItem label="Second Seat" value={secondSeatCohorts} isSelected={isSelectedCohort} toggleFilter={toggleFilterCohort}/>
                <FilterListItem label="Third Seat" value={thirdSeatCohorts} isSelected={isSelectedCohort} toggleFilter={toggleFilterCohort}/>
                <FilterListItem label="Fourth Seat" value={fourthSeatCohorts} isSelected={isSelectedCohort} toggleFilter={toggleFilterCohort}/>
            </FilterList>
            <SimpleForm toolbar={false} defaultValues={{departmentID: nontransparentDepartmentID, groupID: nontransparentGroupID }} sx={{padding: "0px", paddingTop: "16px", paddingBottom: "0px !important"}}>
                <FilterDepartments {...{setDepartmentID, setGroupID}} />
            </SimpleForm>

        </CardContent>
    </Card>
    )}else {
        return (<></>)
    }
}

const FilterDepartments = ({setGroupID, setDepartmentID}) => {
    const { watch, setValue } = useFormContext()

    const updateTransparencyFilters = (data, {name, type}) => {
        if (type == "change" && name == "groupID") {
            setGroupID(data.groupID)
            setDepartmentID("")
            setValue("departmentID", "")
        }
        if (type == "change" && name == "departmentID") {
            setDepartmentID(data.departmentID)
            setGroupID("")
            setValue("groupID", "")
        }
    }
    watch(updateTransparencyFilters)


    return (
        <>
            <ReferenceInput source="groupID" reference='groups'  filter={{ groupsByTypeAndName: { type: "Groups" } }} perPage={100}>
                <SelectInput label="Group" sx={{ width: "100%", minWidth: "100%" }} />
            </ReferenceInput>
            <ReferenceInput source="departmentID" reference='departments'  filter={{ departmentsByTypeAndName: { type: "Department" } }} perPage={1000} >
            <SelectInput label="Department" sx={{ width: "100%", minWidth: "100%" }} optionText={record => <DepartmentPlusSiteField record={record} />}/>
        </ReferenceInput>
        </>
    )
}


const TraineeListActions = ({isLoading,currentRotation,sidebarOpen, setSidebarOpen}) => (
    <TopToolbar sx={{width: "100%"}} >
        <Button onClick={() => {setSidebarOpen(!sidebarOpen)}} label="Filters"/>
        <Box display="flex"  flexDirection="column" sx={{width: "100%", height: "50px"}}>
            <Box display="flex" flexDirection="column" justifyContent='center'  sx={{ flexGrow: 1, position: "fixed", 'z-index': 3 }}>
                <Paper elevation={3} mt={1}>
                    {isLoading ? null : <RotationDataField currentRotation={currentRotation} />}
                </Paper>
            </Box>
        </Box>
        <TraineeListButtons {...{isLoading,currentRotation}}/>
    </TopToolbar>
);

const TraineeListButtons = ({isLoading,currentRotation}) => {
    const { permissions } = usePermissions();
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const [assignSeatsLoading, setAssignSeatsLoading] = useState(false)
    const [publishSeatsLoading, setPublishSeatsLoading] = useState(false)
    const [referenceSeatsLoading, setReferenceSeatsLoading] = useState(false)
    const rotationID = currentRotation?.id
    const [optiseatIndexWeighting,setOptiseatIndexWeighting] = useState(0)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const assignSeats = () => {
        const apiName = 'assignSeats';
        const path = '/assignseats';

        let jwt;
        Auth.currentSession().then(data => {
            // console.log(data)
            // console.log(data.idToken)
            const myInit = {
                headers: {
                    Authorization: data.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {
                    'rotationID': rotationID,
                    'optiseatIndexWeighting': optiseatIndexWeighting
                }
            };
            setAssignSeatsLoading(true);
            API.get(apiName, path, myInit).then(() => { setAssignSeatsLoading(false); refresh() }).catch(error => { setAssignSeatsLoading(false); notify(error.response.data, { type: 'error' }); })
        })
    }

    const publishSeats = () => {
        const apiName = 'publishSeats';
        const path = '/publishseats';

        let jwt;
        Auth.currentSession().then(data => {
            // console.log(data)
            // console.log(data.idToken)
            const myInit = {
                headers: {
                    Authorization: data.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {
                    'rotationID': rotationID
                }
            };
            setPublishSeatsLoading(true);
            API.get(apiName, path, myInit).then(() => { setPublishSeatsLoading(false); refresh() }).catch(error => { setPublishSeatsLoading(false); notify(error.response.data, { type: 'error' }); })
        })
    }

    const referenceSeats = () => {
        const apiName = 'referenceSeats';
        const path = '/';

        let jwt;
        Auth.currentSession().then(data => {
            // console.log(data)
            // console.log(data.idToken)
            const myInit = {
                headers: {
                    Authorization: data.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {
                    'rotationID': rotationID
                }
            };
            setReferenceSeatsLoading(true);
            API.get(apiName, path, myInit).then(() => { setReferenceSeatsLoading(false); refresh() }).catch(error => { setReferenceSeatsLoading(false); notify(error.response.data, { type: 'error' }); })
        })
    }


    const marks = [
        {
          value: 0,
          label: '0',
        },
        {
          value: 1,
          label: '1',
        },
        {
          value: 2,
          label: '2',
        },
        {
          value: 3,
          label: '3',
        },
        {
          value: 4,
          label: '4',
        },
        {
          value: 5,
          label: '5',
        },
      ];
    return (
        <Box display="flex" justifyContent='center' alignItems="flex-end" >
            <Typography m={2}>OptiSeat Index Weighting:</Typography>
            <Slider
            aria-label="Temperature"
            defaultValue={optiseatIndexWeighting}
            valueLabelDisplay="off"
            shiftStep={1}
            step={1}
            min={0}
            max={5}
            m={1}
            marks={marks}
            sx={{width: 100, marginRight: 20}}
            onChange={(_, value) => setOptiseatIndexWeighting(value)}
            />
        <Box component="span" mr={2} >
                            {isLoading ? null : <TraineeExportButton exporter={exporter} params={{rotationID: currentRotation?.id}} size='large' variant="outlined" color="primary" resource="trainees" />}
                </Box>
        {permissions?.includes("SuperAdmins") &&
                            <Box component="span" mr={2}>
                                <CreateButton label="Add Trainee" size='large' variant="outlined" color="primary" resource="trainees" />
                            </Box>}
                         <Box component="span" mr={2} >
                            <Button size='large' variant="outlined" color="primary" onClick={referenceSeats} disabled={currentRotation?.published || referenceSeatsLoading} label={referenceSeatsLoading ? <CircularProgress size="26px" /> : "Set Reference"}/>
                        </Box>
                        <Box component="span" mr={2}>
                            <Button size='large' variant="outlined" color="primary" onClick={assignSeats} disabled={currentRotation?.published || assignSeatsLoading} label={assignSeatsLoading ? <CircularProgress size="26px" /> : "Assign seats"}/>                                
                        </Box>

                        <Box component="span" mr={2} >
                            <Button size='large' variant="outlined" color="primary" onClick={handleClickOpen} disabled={currentRotation?.published || assignSeatsLoading} label=                                {publishSeatsLoading ? <CircularProgress size="26px" /> : "Publish seats"}/>
                        </Box>
                        <SelectColumnsButton />
                        <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Publish seats"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Publishing seats will freeze the currently assigned seats, make them visible to the trainees and email them notifying them of their seat for the next rotation.
                        <br /><br />It will also update the seat supervisors on the trainee views, so make sure you have finished entering any of these you wish to before publishing.
                        <br /><br />This process is <b>irreversible</b>. Are you sure you want to continue?
                    </DialogContentText>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleClose} label="Close"/>
                    <Button onClick={() => { handleClose(); publishSeats(); }} autoFocus label="Continue"/>
                </DialogActions>
            </Dialog >
        </Box>
    )
}



export const SeatFieldCropped = (props) => {
    const { nontransparentDepartmentID,nontransparentGroupID, show_review } = props
    const record = useRecordContext();
    // console.log('seat', record)
    var priority = record['preferencePriority']
    if (priority == 0) {
        priority = parseInt(process.env.REACT_APP_NUM_OF_PREFRENCES) + 1
    }
    if (priority != null) {
        if (priority > process.env.REACT_APP_NUM_OF_PREFRENCES) {
            priority = parseInt(process.env.REACT_APP_NUM_OF_PREFRENCES) + 1
        }
        const text_color = is_preference_white_text(priority) ? 'white' : 'black'

        return (
            <ReferenceField source="departmentID" reference="departments" label="Department" sx={{ '& .MuiChip-label': { color: 'black' } }} link={!props?.nolink && "show"}  >
                <SeatChipFieldCropped nontransparentDepartmentID={nontransparentDepartmentID} nontransparentGroupID={nontransparentGroupID} show_review={record.review} priority={priority} text_color={text_color}/>
            </ReferenceField>
        )
    } else {
        return (
            <ReferenceField source="departmentID" reference="departments" label="Department" sx={{ '& .MuiChip-label': { color: 'black' } }} link={!props?.nolink && "show"} >
                <FunctionField render={record => { return (<Chip label={<DepartmentPlusSiteField record={record} />} />) }} />
            </ReferenceField>
        )
    }
}


export const SeatChipFieldCropped = ({ priority, text_color,nontransparentDepartmentID,nontransparentGroupID, show_review }) => {
    // TODO: make nontransaparent if nontransparentGroupID == groupID
    const record = useRecordContext();
    if (nontransparentDepartmentID) {
        if (record.id == nontransparentDepartmentID) {
            return (
                <MouseOverPopoverChip msg={<NamePlusReview show_review={show_review} record={record}/>} sx={{ backgroundColor: get_color_code(priority), '& .MuiChip-label': { color: text_color }, width: 120 }}/>
            )
        } else {
            return (
                <MouseOverPopoverChip msg={<NamePlusReview show_review={show_review} record={record}/>} sx={{ backgroundColor: get_color_code(priority), opacity:0.2,'& .MuiChip-label': { color: text_color }, width: 120 }}/>
            )
        }
    }
    if (nontransparentGroupID) {
        if (record.groupID == nontransparentGroupID) {
            return (
                <MouseOverPopoverChip msg={<NamePlusReview show_review={show_review} record={record}/>} sx={{ backgroundColor: get_color_code(priority), '& .MuiChip-label': { color: text_color }, width: 120 }}/>
            )
        } else {
            return (
                <MouseOverPopoverChip msg={<NamePlusReview show_review={show_review} record={record}/>} sx={{ backgroundColor: get_color_code(priority), opacity:0.2,'& .MuiChip-label': { color: text_color }, width: 120 }}/>
            )
        }
    }
    return (
        <MouseOverPopoverChip msg={<NamePlusReview show_review={show_review} record={record}/>} sx={{ backgroundColor: get_color_code(priority), '& .MuiChip-label': { color: text_color }, width: 120 }}/>
        )
}


export const TraineePreferencesListCropped = ({ priority, links, nontransparentDepartmentID,nontransparentGroupID }) => {
    const record = useRecordContext();
    if (record.priority === priority) {
        return (
            <ReferenceField source="departmentID" reference="departments" label="Department" link={links} sx={{ '& .MuiChip-label': { color: 'black' } }} >
                <PreferenceFieldCropped nontransparentDepartmentID={nontransparentDepartmentID} nontransparentGroupID={nontransparentGroupID}  />
            </ReferenceField>
        )
    }
}

export const PreferenceFieldCropped = ({ priority,nontransparentDepartmentID,nontransparentGroupID }) => {
    // TODO: make nontransaparent if nontransparentGroupID == groupID
    const record = useRecordContext();
    if (nontransparentDepartmentID) {
        if (record.id == nontransparentDepartmentID) {
            return (
                <MouseOverPopoverChip msg={<DepartmentPlusSiteField  record={record}/>} sx={{width: 120, opacity: 1}}/>
            )
        } else {
            return (
                <MouseOverPopoverChip msg={<DepartmentPlusSiteField  record={record}/>} sx={{width: 120, opacity: 0.2}}/>
            )
        }
    }
    if (nontransparentGroupID) {
        if (record.groupID == nontransparentGroupID) {
            return (
                <MouseOverPopoverChip msg={<DepartmentPlusSiteField  record={record}/>}sx={{width: 120, opacity: 1}}/>
            )
        } else {
            return (
                <MouseOverPopoverChip  msg={<DepartmentPlusSiteField  record={record}/>} sx={{width: 120, opacity: 0.2}}/>
            )
        }
    }
    return (
        <MouseOverPopoverChip msg={<DepartmentPlusSiteField  record={record}/>} sx={{width: 120, opacity: 1}}/>
    )
}