import { Card, Box, CircularProgress, Paper } from '@mui/material'
import * as React from 'react';
import { API, Auth } from 'aws-amplify';
import { TrueIcon, FalseIcon } from './icons';
import {
    useGetIdentity,
    useShowController,
    RecordContextProvider,
    SimpleShowLayout,
    TextField,
    ReferenceManyField,
    Datagrid,
    FunctionField,
    ReferenceField,
    useGetOne,
    SingleFieldList,
    useRecordContext,
    ChipField,
    WrapperField,
    List, NumberField,
    usePermissions,
    useStore,
    Button,
    useRedirect,
    DateField,
    useGetList,
    ListContextProvider,
    ShowButton,
    BooleanField,
    EditButton,
    useRefresh,
    useNotify,
    SimpleList,
    Labeled,
    Toolbar,
    SaveButton,
    SelectInput,
    ReferenceInput,
    RichTextField,
    Form, downloadCSV, Edit
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import jsonExport from 'jsonexport/dist';

import { SeatField, SeatChipField,DepartmentPlusSiteField, TraineeShowPageLink } from './Trainee';
import {TraineeExportButton} from './TraineeExportButton';
import {
    Typography, Grid, TableCell,
    TableHead,
    TableRow,
    useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Button as MuiButton, ListItem, List as MuiList
} from '@mui/material';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails
} from './Accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RotationDataField } from "./RotationFilterForm"
import { NewPreferenceButton, PostPagination, TraineePreferencesList, ManualOverrideField, TraineeCommentsField } from "./Trainee"
import { formatDate, indexToYear } from "./Rotation"
import { DataGridWithIndex } from './DataGridWithIndex'
import { DepartmentStatisticsList } from "./DepartmentStatistics"
import { ordinal_suffix_of, get_num_of_preferences } from "./variable_preferences"

export const RotationPreferences = ({ priority, traineeRecord, links }) => {
    const record = useRecordContext();
    const filterValues = { preferencesByTraineeIDAndRotationID: { rotationID: { "eq": record.id } } };

    return (
        <ReferenceManyField reference="preferences" target="preferencesByTraineeIDAndRotationID.traineeID.rotationID" filter={filterValues} record={traineeRecord}>
            <SingleFieldList linkType={false}>
                <TraineePreferencesList priority={priority} clickable={false} links={links} />
            </SingleFieldList>
        </ReferenceManyField>
    )
}


export const RotationSeat = ({ traineeRecord,edit_review }) => {

    const record = useRecordContext();
    const seatsFilterValues = { seatsByTraineeIDAndRotationID: { rotationID: { "eq": record.id } } };
    const publishedSeatsFilterValues = { publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": record.id } } };
    const { permissions } = usePermissions();

    if (record.published) {
        return (
            <ReferenceManyField label="Published Seats" reference='publishedSeats' target='publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID' filter={publishedSeatsFilterValues} record={traineeRecord} >
                <SingleFieldList linkType={permissions.includes("Admins") && "edit"}>
                    <SeatField nolink={permissions.includes("Admins")} show_review={edit_review} />
                </SingleFieldList>
                {edit_review && process.env.REACT_APP_QUALIFYING_TRAINEES_ON == "1" && <EditButton label="Qualification Indicator"/>}
            </ReferenceManyField>)
    } else {
        return (
            <ReferenceManyField label="Assigned Seat" reference='seats' target='seatsByTraineeIDAndRotationID.traineeID.rotationID' filter={seatsFilterValues} record={traineeRecord}  >
                <SingleFieldList linkType={false}>
                    <SeatField show_review={edit_review} />
                </SingleFieldList>
                {edit_review && process.env.REACT_APP_QUALIFYING_TRAINEES_ON == "1" && <EditButton label="Qualification Indicator"/>}
            </ReferenceManyField>)
    }
}

export const RotationPublishedSeat = ({ traineeRecord,edit_review }) => {

    const record = useRecordContext();
    const publishedSeatsFilterValues = { publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": record.id } } };
    return (
        <ReferenceManyField label="Published Seats" reference='publishedSeats' target='publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID' filter={publishedSeatsFilterValues} record={traineeRecord} >
            <SingleFieldList linkType={false}>
                <SeatField show_review={edit_review} />
                {edit_review&& process.env.REACT_APP_QUALIFYING_TRAINEES_ON == "1" && <EditButton label="Qualification Indicator"/>}
            </SingleFieldList>
        </ReferenceManyField>)
}

export const CommentsField = ({traineeRecord}) => {
    const record = useRecordContext();

    const commentsFilterValues = { traineeCommentsByTraineeIDAndRotationID: { rotationID: { "eq": record.id } } };
    return (<ReferenceManyField  reference='traineeComments' target="traineeCommentsByTraineeIDAndRotationID.traineeID.rotationID" filter={commentsFilterValues} record={traineeRecord}>
    <SingleFieldList linkType={false}>
       <TraineeCommentsField />
    </SingleFieldList>
</ReferenceManyField>)
}
export const RotationPreferencesList = ({ traineeRecord, links, edit_review }) => {

    const { permissions } = usePermissions();
    const record = useRecordContext();

    if (!traineeRecord) {
        traineeRecord = record;
    }
    const id = record.cohortID;

    const { isLoading, data } = useGetOne("rotations", { id })
    if (!isLoading) {
        // console.log("record", record)
        var preferenceColumns = []
        for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
            preferenceColumns.push(
                <WrapperField label={ordinal_suffix_of(priority + 1) + " Preference"} >
                    <RotationPreferences priority={priority + 1} traineeRecord={traineeRecord} links={links} />
                </WrapperField>)
        }
        return (
            <List label="Rotation" resource="rotations" actions={false} pagination={<PostPagination />} filter={{ rotationsByTypeAndStartDate: {type:"Rotation",startDate: { ge: data?.startDate},  filter: { visible: {eq: true}} }} }>
                <Datagrid bulkActionButtons={false} sx={{
                    '& .RaDatagrid-rowCell': { textAlign: 'left' },
                    '& .RaDatagrid-headerCell': { textAlign: 'left' },
                    '& .RaDatagrid-row': { height: '45px' }
                }}>
                    <FunctionField source="startDate" render={record => formatDate(record.startDate)} label="Rotation Date" />
                    {permissions?.includes("Admins") &&
                        <WrapperField label="Seat Allocated" >
                            <RotationSeat traineeRecord={traineeRecord} edit_review={edit_review}/>
                        </WrapperField>}
                        {!permissions?.includes("Admins") &&
                        <WrapperField label="Seat Allocated" >
                            <RotationPublishedSeat traineeRecord={traineeRecord} edit_review={edit_review}/>
                        </WrapperField>}
                    {preferenceColumns}
                    {!permissions?.includes("Admins") && <NewPreferenceButton traineeRecord={traineeRecord} rotationID={false} />}
                    <WrapperField label="Comments" >
                        <CommentsField traineeRecord={traineeRecord}/>
                    </WrapperField>
                </Datagrid>
            </List>
        )
    }
}

export const RotationPreferencesListMobile = ({ links, record }) => {
    const departmentList = useGetList('departments', { filter: { departmentsByTypeAndName: { type: "Department" } }, pagination: { page: 1, perPage: 1000 } });
    var departmentMap = {}
    for (const department in departmentList?.data) {
        departmentMap[departmentList?.data[department].id] = departmentList?.data[department].name
    }

    const preferencesList = useGetList('preferences', { filter: { preferencesByTraineeIDAndRotationID: { traineeID: record?.id } }, pagination: { page: 1, perPage: 1000 } });
    const { isLoading, data } = useGetOne("rotations", { id: record?.cohortID })
    if (!isLoading && !preferencesList.isLoading && !departmentList.isLoading) {
        const preferences = preferencesList?.data
        var rotationsToPreferencesMap = {}
        for (const preference in preferences) {
            if (preferences[preference]?.rotationID in rotationsToPreferencesMap) {
                rotationsToPreferencesMap[preferences[preference]?.rotationID].push({ name: departmentMap[preferences[preference]?.departmentID], priority: preferences[preference]?.priority })
            } else {
                rotationsToPreferencesMap[preferences[preference]?.rotationID] = [{ name: departmentMap[preferences[preference]?.departmentID], priority: preferences[preference]?.priority }]
            }
        }
        for (const rotation in rotationsToPreferencesMap) {
            rotationsToPreferencesMap[rotation]?.sort((a, b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))
        }

        return (
            <List sx={{ width: "100%" }} label="Rotation" resource="rotations" actions={false} pagination={false} filter={{ rotationsByTypeAndStartDate: {type:"Rotation",startDate: { ge: data?.startDate},  filter: { visible: {eq: true}} }}}>
                <SimpleList secondaryText={record => rotationsToPreferencesMap[record.id]?.length ? "Preferences: " + rotationsToPreferencesMap[record.id].map((department) => department?.name).join(", ") : ""} tertiaryText={record => record?.PublishedSeats?.items[0]?.departmentID ? departmentMap[record?.PublishedSeats?.items[0]?.departmentID] : "Not allocated yet"}
                    primaryText={record => formatDate(record.startDate)} linkType={false} />
            </List>
        )
    }
}

const TraineeBySeatsHeader = ({ rotationsList }) => {
    var rotationsHeaders = []
    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentCohorts = rotations?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible));

    for (const rotation in currentCohorts) {
        rotationsHeaders.push(
            <TableCell key={currentCohorts[rotation].startDate}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 14 }} >{formatDate(currentCohorts[rotation].startDate)} </Typography>
                {currentCohorts[rotation].published ? null : <Typography sx={{ fontWeight: 'bold', fontSize: 14 }} >(not published)</Typography>}
            </TableCell>)
    }
    return (
        <TableHead >
            <TableRow>
                <TableCell ></TableCell>
                <TableCell key={"name"}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{"Trainee"} </Typography>
                </TableCell>
                <TableCell key={"cohortID"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Cohort"} </Typography>
                </TableCell>
                {process.env.REACT_APP_MULTISITE == "1" &&
                    <TableCell key={"siteID"}>
                        <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Office"} </Typography>
                    </TableCell>
                }
                {/* {process.env.REACT_APP_DESIRED_GRADUATION_SEAT_ON != "0" && <TableCell key={"desiredGraduatingSeatID"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Desired Qualification Area"} </Typography>
                </TableCell>} */}
                {rotationsHeaders}
                <TableCell key={"manualOverride"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Manual override"} </Typography>
                </TableCell>
                {process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME != "0" && <TableCell key={"litigationSeatCompleted"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME + " Seat Completed"} </Typography>
                </TableCell>}
                {process.env.REACT_APP_BANKING_DEPARTMENT_NAME != "0" && <TableCell key={"bankingSeatCompleted"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{process.env.REACT_APP_BANKING_DEPARTMENT_NAME + " Seat Completed"} </Typography>
                </TableCell>}
                <TableCell >

                </TableCell>
            </TableRow>
        </TableHead>
    )
};

const QualifyingTraineesHeader = ({ rotationsList }) => {
    var rotationsHeaders = []
    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentCohorts = rotations?.filter((rotation) => (rotation.cohortsIndex <= 11&& rotation.visible));

    for (const rotation in currentCohorts) {
        rotationsHeaders.push(
            <TableCell key={currentCohorts[rotation].startDate}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 14 }} >{formatDate(currentCohorts[rotation].startDate)} </Typography>
                {currentCohorts[rotation].published ? null : <Typography sx={{ fontWeight: 'bold', fontSize: 14 }} >(not published)</Typography>}
            </TableCell>)
    }
    return (
        <TableHead >
            <TableRow>
                <TableCell ></TableCell>
                <TableCell key={"name"}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{"Trainee"} </Typography>
                </TableCell>
                <TableCell key={"cohortID"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Cohort"} </Typography>
                </TableCell>
                {process.env.REACT_APP_MULTISITE == "1" &&
                    <TableCell key={"siteID"}>
                        <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Office"} </Typography>
                    </TableCell>
                }
                {rotationsHeaders}
                {process.env.REACT_APP_DESIRED_GRADUATION_SEAT_ON != "0" && <TableCell key={"desiredGraduatingSeatID"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Desired Qualification Area"} </Typography>
                </TableCell>}
                <TableCell >

                </TableCell>
            </TableRow>
        </TableHead>
    )
};

export const TraineeBySeats = (props) => {

    const refresh = useRefresh();
    const notify = useNotify();
    const [assignSeatsLoading, setAssignSeatsLoading] = React.useState(false)

    const rotationsList = useGetList('rotations', { filter: { rotationsByTypeAndStartDateAdmin: { type: "Rotation" } } });
    var columns = []

    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentCohorts = rotations?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible));
    const currentRotation = currentCohorts?.at(-1)

    for (const rotation in currentCohorts?.slice(0, -1)) {
        columns.push(
            <ReferenceManyField label={currentCohorts[rotation].startDate} reference="preferences" target="publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID" filter={{ publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": currentCohorts[rotation].id } } }} link={false}>
                <SingleFieldList linkType={false} >
                    <SeatField />
                </SingleFieldList>
            </ReferenceManyField>)
    }
    if (!currentRotation?.published) {
        columns.push(
            <ReferenceManyField label={currentRotation?.startDate} reference="preferences" target="seatsByTraineeIDAndRotationID.traineeID.rotationID" filter={{ seatsByTraineeIDAndRotationID: { rotationID: { "eq": currentRotation?.id } } }} link={false}>
                <SingleFieldList linkType={false}>
                    <SeatField />
                </SingleFieldList>
            </ReferenceManyField>
        )
    } else {
        columns.push(
            <ReferenceManyField label={currentRotation?.startDate} reference="preferences" target="publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID" filter={{ publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": currentRotation?.id } } }} link={false}>
                <SingleFieldList linkType={false}>
                    <SeatField />
                </SingleFieldList>
            </ReferenceManyField>)
    }

    const assignSeats = () => {
        const apiName = 'assignSeats';
        const path = '/assignseats';

        let jwt;
        Auth.currentSession().then(data => {
            // console.log(data)
            // console.log(data.idToken)
            const myInit = {
                headers: {
                    Authorization: data.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {
                    'rotationID': currentRotation.id
                }
            };
            setAssignSeatsLoading(true);
            API.get(apiName, path, myInit).then(() => { setAssignSeatsLoading(false); refresh() }).catch(error => { setAssignSeatsLoading(false); notify(error.response.data, { type: 'error' }); })
        })
    }
    const [page, setPage] = useStore('trainee.page', 1);
    const [perPage, setPerPage] = useStore('trainee.perPage', 100);
    const sort = { field: 'name', order: 'ASC' };
    const { data, pageInfo, isLoading } = useGetList('trainees', {
        filter: { traineesByTypeAndTraineeIndex: { type: 'Trainee', filter: { or: currentCohorts?.map((rotation) => ({ cohortID: { eq: rotation.id } })) } } },
        pagination: { page, perPage },
        sort,
    });
    if (isLoading) { return (<></>) }
    const { hasNextPage, hasPreviousPage } = pageInfo
    // const { hasNextPage, hasPreviousPage } = pageInfo;
    return (
        <ListContextProvider value={{ data, page, perPage, setPage, setPerPage, hasNextPage, hasPreviousPage, sort }} >
            <Box component="block" >
            <Box display="flex" flexDirection="column" justifyContent='center' mb={1} sx={{ flexGrow: 1, position: "fixed", 'z-index': 3 }}>
                <Paper elevation={3} mt={1}>
                <RotationDataField currentRotation={currentRotation} sx={{position: 'fixed'}}/>

                        </Paper>
                    </Box>
                <Box display="flex" alignItems="stretch" alignContent='space-between' mb={1}flexDirection={'row-reverse'} >
                   
                    <Button size='large' variant="outlined" color="primary" onClick={assignSeats} disabled={currentRotation?.published || assignSeatsLoading}>
                        {assignSeatsLoading ? <CircularProgress size="26px" /> : "Assign seats"}
                    </Button>
                </Box>
            </Box>
            <div style={{ marginTop: "20px" }}>
                <Card  >
                    <DataGridWithIndex sort={sort} sx={{
                        '& .column-id': { textAlign: 'left' },
                        '& .column-name': { textAlign: 'left' },
                    }} header={<TraineeBySeatsHeader rotationsList={rotationsList} />}>
                        <WrapperField source="name">
                            <TraineeShowPageLink />
                        </WrapperField>
                        <ReferenceField source="cohortID" reference="rotations" label="Cohort" link={false}>
                            <FunctionField label="Cohort" render={(record) => indexToYear(record.cohortsIndex)} />
                        </ReferenceField>
                        {process.env.REACT_APP_MULTISITE == "1" &&
                            <ReferenceField source="siteID" reference="sites" label="Office" link={false}>
                                <TextField source="name" />
                            </ReferenceField>
                        }
                        {/* {process.env.REACT_APP_DESIRED_GRADUATION_SEAT_ON != "0" && <ReferenceField label="Desired Qualification Area" source="desiredGraduatingSeatID" reference='departments' link="show" />} */}
                        {columns}
                        <ManualOverrideField {...props} label="Manual Override" />
                        {process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME != "0" && <BooleanField source="litigationSeatCompleted" TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>}
                        {process.env.REACT_APP_BANKING_DEPARTMENT_NAME != "0" && <BooleanField source="bankingSeatCompleted" TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>}
                        {/* <ShowButton resource='trainees' /> */}
                    </DataGridWithIndex>
                </Card>
                <PostPagination />
            </div>
        </ListContextProvider>
    );
};

export const ShowUserProfile = (data) => {
    const showController = useShowController({ resource: 'trainees', id: data.id + "::" + data.id });

    const traineeRecord = showController?.record;
    const linkGenerator = (record, resource) => `/departments/${record.id}/show`

    return (
        <RecordContextProvider value={showController?.record}>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <TextField source="name" />
                        <ReferenceField source="cohortID" reference="rotations" label="Training Contract Start Date" />
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <TextField source="email" label="Username" />
                        {process.env.REACT_APP_DESIRED_GRADUATION_SEAT_ON != "0" && <WrapperField label="Desired Qualification Area"><ReferenceField label="Desired Qualification Area" source="desiredGraduatingSeatID" reference='departments' >
                            <TextField source='name' />
                        </ReferenceField>
                        <EditButton resource='trainees' label="Edit" />
                        </WrapperField>
                        }
                    </SimpleShowLayout>
                </Grid>
            </Grid>
            <SimpleShowLayout>
                <Typography display="block">
                Welcome to the Optiseat platform.  We're looking forward to supporting your seat preferences for the next 6 months by continuing to have one to one conversations, and then capturing your choices through this platform. The Optiseat platform not only collates your choices but helps us model what the best seating plan might look like across the trainee group.  Please don't worry, we are still taking all your reasons and previous seats into account as per usual, it just helps us speed up the process!
                </Typography>
                <Typography display="block">
Our <Link to="/departments">Departments</Link> section will let you know the different departments that are offering trainee seats in each office, you can also find more information on the sort of work trainees do. Once you have researched the options available, add your preferences below and save your choices.
</Typography>
<Typography display="block">
Once everyone has submitted their preferences, the Early Talent Team will review.
</Typography>
                <Typography display="block">
                    <i>
                        If you have any technical difficulties please contact <a href="mailto:admin@optiseat.co.uk">admin@optiseat.co.uk</a>          </i>
                </Typography>
                <RotationPreferencesList traineeRecord={traineeRecord} links={linkGenerator} edit_review={true}/>
                <Grid container >
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="First Seat Supervisor">
                                <TextField source="firstSeatSupervisor" />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="Second Seat Supervisor">
                                <TextField source="secondSeatSupervisor" />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="Third Seat Supervisor">
                                <TextField source="thirdSeatSupervisor" />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="Fourth Seat Supervisor">
                                <TextField source="fourthSeatSupervisor" />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
                {/* <WrapperField label="Your Comments">
                    <RichTextField source="traineeDiary" label="Your Comments" />
                    <EditButton resource='trainees' label="Edit" />
                </WrapperField> */}
            </SimpleShowLayout>
        </RecordContextProvider>
    )
}

export const ShowUserProfileMobile = (data) => {
    const showController = useShowController({ resource: 'trainees', id: data.id + "::" + data.id });
    const redirect = useRedirect();
    const rotationsList = useGetList('rotations');
    var columns = []

    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentCohorts = rotations?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible));
    const currentRotation = currentCohorts?.at(-1)

    const currentPreferencesList = useGetList('preferences', { filter: { preferencesByTraineeIDAndRotationID: { traineeID: showController?.record?.id, rotationID: { 'eq': currentRotation?.id } } } });
    const linkGenerator = (record, resource) => `/departments/${record.id}/show`
    return (
        <RecordContextProvider value={showController?.record}>

            <SimpleShowLayout>
                <TextField source="name" />
                <ReferenceField source="cohortID" reference="rotations" label="Training Contract Start Date" />

                <TextField source="email" label="Username" />


                <Typography display="block">
                Welcome to the Optiseat platform.  We're looking forward to supporting your seat preferences for the next 6 months by continuing to have one to one conversations, and then capturing your choices through this platform. The Optiseat platform not only collates your choices but helps us model what the best seating plan might look like across the trainee group.  Please don't worry, we are still taking all your reasons and previous seats into account as per usual, it just helps us speed up the process!
                </Typography>
                <Typography display="block">
Our <Link to="/departments">Departments</Link> section will let you know the different departments that are offering trainee seats in each office, you can also find more information on the sort of work trainees do. Once you have researched the options available, add your preferences below and save your choices.
</Typography>
<Typography display="block">
Once everyone has submitted their preferences, the Early Talent Team will review.
</Typography>
                <Typography display="block">
                    <i>
                        If you have any technical difficulties please contact <a href="mailto:admin@optiseat.co.uk">admin@optiseat.co.uk</a>          </i>
                </Typography>
                
                <Labeled label='Allocated Seats' sx={{ width: "100%", fontSize: "14pt" }} >
                    <RotationPreferencesListMobile links={linkGenerator} record={showController?.record} />
                </Labeled>

                {!currentPreferencesList?.data?.length && <Box textAlign='center'><MuiButton size='small' variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => { redirect('create', 'preferences', 1, {}, { record: { traineeID: data.id, rotationID: currentRotation.id } }) }}>
                    Add Preferences for upcoming rotation
                </MuiButton></Box>}
                <TextField source="firstSeatSupervisor" emptyText="Not assigned yet" />
                <TextField source="secondSeatSupervisor" emptyText="Not assigned yet" />
                <TextField source="thirdSeatSupervisor" emptyText="Not assigned yet" />
                <TextField source="fourthSeatSupervisor" emptyText="Not assigned yet" />
                {/* <WrapperField label="Your Comments">
                    <RichTextField source="traineeDiary" label="Your Comments" />
                    <EditButton resource='trainees' label="Edit" />
                </WrapperField> */}
            </SimpleShowLayout>
        </RecordContextProvider>
    )
}

const GoToTraineeViewButton = () => {

    const redirect = useRedirect();
    const record = useRecordContext();
    const [, setFilter] = useStore('trainee.filter')
    return (
        <Button onClick={(e) => { setFilter(record.id); redirect('list', 'trainees') }} label='Edit' />
    )
}

const qualifyingTraineeExporter = (records, fetchRelatedRecords, dataProvider, params) => {
    const {currentCohorts, siteID, qualifyingTrainee_currentCohorts, nontransparentDepartmentID,nontransparentGroupID } = params
    console.log("currentCohortID",params)
    // console.log("records", records.filter((trainee)=> {
    //     const { siteID: traineeSiteID , cohortID: traineeCohortID} = trainee
    //     console.log(traineeSiteID, traineeCohortID,siteID, currentCohortID)
    //     if (siteID  && currentCohortID && siteID == traineeSiteID && currentCohortID == traineeCohortID ) {
    //         return true
    //     } else if (siteID && !currentCohortID && siteID == traineeSiteID) {
    //         return true
    //     } else if (!siteID && currentCohortID && currentCohortID == traineeCohortID) {
    //         return true
    //     } else if (!siteID && !currentCohortID) {
    //         return true
    //     } else {
    //         return false
    //     }}));
    Promise.all(records.filter((trainee)=> {
        const { siteID: traineeSiteID , cohortID: traineeCohortID} = trainee
        if (siteID  && siteID == traineeSiteID  ) {
            for (var cohort in qualifyingTrainee_currentCohorts){
                if (traineeCohortID == qualifyingTrainee_currentCohorts[cohort].id) {
                    return true
                }
            }
            return false
        } else if (!siteID){
            for (var cohort in qualifyingTrainee_currentCohorts){
                if (traineeCohortID ==qualifyingTrainee_currentCohorts[cohort].id) {
                    console.log("true")
                    return true
                }
            }
            return false
        } else {
            return false
        }}).sort((a,b)=>a.name.localeCompare(b.name)).map(async trainee => {
        const { id, Preferences, cohort, cohortID,siteID, Seats, PublishedSeats, manualOverride, manualOverrideID, createdAt, type, updatedAt, ownerID, litigationSeatCompleted, bankingSeatCompleted, desiredGraduatingSeatID, desiredGraduatingSeat, traineeDiary, __typename, hrComments, firstSeatSupervisorHR, secondSeatSupervisorHR, thirdSeatSupervisorHR, fourthSeatSupervisorHR, ...traineeForExport } = trainee; // omit backlinks and author
        console.log("desiredGraduatingSeatID", desiredGraduatingSeatID, desiredGraduatingSeat)
        var assignedSeats = {};
        // for (var seat in Seats.items) {
        //     for (var rotation in currentCohorts) {
        //         if (Seats.items[seat].rotationID == currentCohorts[rotation].id) {
        //             var assignedSeat = await dataProvider.getOne('departments', { id: Seats.items[seat].departmentID });
        //             assignedSeats[currentCohorts[rotation].startDate] = assignedSeat.name;
        //         }
        //     }
        // }
        var assignedSeat;
        for (var seat in PublishedSeats.items) {
            for (var rotation in currentCohorts) {
                if (PublishedSeats.items[seat].rotationID == currentCohorts[rotation].id) {
                    assignedSeat = await dataProvider.getOne('departments', { id: PublishedSeats.items[seat].departmentID });

                    assignedSeat = assignedSeat.data.name
                    assignedSeats[currentCohorts[rotation].startDate] = assignedSeat
                    console.log(assignedSeat)
                }
            }
        }
        var desiredGraduatingSeatName =  desiredGraduatingSeatID ? await dataProvider.getOne('departments', { id: desiredGraduatingSeatID }) : "";
        console.log("desiredGraduatingSeatName", desiredGraduatingSeatName)
        return {
            ...traineeForExport,
            ...assignedSeats,
            'Desired Graduation Seat': desiredGraduatingSeatName.data?.name,
            'Cohort Start Date': cohort.startDate,
            'Early Careers Development Team Comments': hrComments?.replace(/<[^>]*>/g, '')?.replace(/\\par[d]?/g, "").replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "").trim(),
            // 'Trainee Comments': traineeDiary?.replace(/<[^>]*>/g, '')?.replace(/\\par[d]?/g, "").replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "").trim(),
            '1st Seat Supervisor': firstSeatSupervisorHR,
            '2nd Seat Supervisor': secondSeatSupervisorHR,
            '3rd Seat Supervisor': thirdSeatSupervisorHR,
            '4th Seat Supervisor': fourthSeatSupervisorHR
        };
    })).then(traineesForExport => {
        jsonExport(traineesForExport, {
            headers: ['name', 'email', 'Cohort Start Date', 'Manual Override', 'Assigned Seat'] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'trainees'); // download as 'posts.csv` file
        });
    });
};


const QualifyingTraineesList = (props) => {
    const rotationsList = useGetList('rotations', { filter: { rotationsByTypeAndStartDateAdmin: { type: "Rotation" } } });

    const [qualifyingTrainee_currentCohorts, setCurrentCohorts] = useStore('qualifyingTrainee.currentCohorts', rotationsList.data?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible)))
    const [rotationID, setFilter] = useStore('qualifyingTrainee.filter');
    const [siteID, setSiteID ] = useStore('qualifyingTrainee.site', "");
    const [nontransparentDepartmentID, setDepartmentID] = useStore('qualifyinTrainee.departmentID',"")
    const [nontransparentGroupID, setGroupID] = useStore('qualifyinTrainee.groupID',"")

    const onSubmit = (values) => {
        if (Object.keys(values).length > 0) {
            console.log(values.cohortsIndex, values.siteID)

            if (values.cohortsIndex != "") {
                var currentCohortsTemp = []
                for (var rotationIndex in rotationsList.data) {
                    if (rotations[rotationIndex].cohortsIndex == values.cohortsIndex){
                        currentCohortsTemp.push(rotations[rotationIndex])
                    }
                }
                if (currentCohortsTemp.length == 0){
                    setCurrentCohorts(rotationsList.data?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible)))
                }else {
                setCurrentCohorts(currentCohortsTemp);
                }
            } 
            console.log(values.cohortsIndex, values.siteID)
            if (values.siteID == null) {
                setSiteID("")
            }
            if (values.siteID != siteID) {
                setSiteID(values.siteID)
            } 
            if (values.departmentID == null) {
                setDepartmentID("")
            }
            if (values.groupID == null) {
                setGroupID("")
            }
            if (values.departmentID != nontransparentDepartmentID) {
                setDepartmentID(values.departmentID)
                setGroupID("")
            } 
            if (values.groupID != nontransparentGroupID) {
                setGroupID(values.groupID)
                setDepartmentID("")
            } 
            console.log(values.cohortsIndex, values.siteID, values.departmentID, values.groupID)
        }
    };
    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentCohorts = rotations?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible));
    
    const currentRotation = currentCohorts?.at(-1)

    var filter = {  or: qualifyingTrainee_currentCohorts?.map((rotation) => ({ cohortID: { eq: rotation.id } })) }

    if (siteID ) {
        filter = { siteID: {eq: siteID}, or: qualifyingTrainee_currentCohorts?.map((rotation) => ({ cohortID: { eq: rotation.id } })) }
       }
    var columns = []

    

    for (const rotation in currentCohorts?.slice(0, -1)) {
        columns.push(
            <ReferenceManyField label={currentCohorts[rotation].startDate} reference="preferences" target="publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID" filter={{ publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": currentCohorts[rotation].id } } }} link={false}>
                <SingleFieldList linkType={false} >
                    <SeatField nontransparentDepartmentID={nontransparentDepartmentID} nontransparentGroupID={nontransparentGroupID} show_review={true}/>
                </SingleFieldList>
            </ReferenceManyField>)
    }
    if (!currentRotation?.published) {
        columns.push(
            <ReferenceManyField label={currentRotation?.startDate} reference="preferences" target="seatsByTraineeIDAndRotationID.traineeID.rotationID" filter={{ seatsByTraineeIDAndRotationID: { rotationID: { "eq": currentRotation?.id } } }} link={false}>
                <SingleFieldList linkType={false}>
                    <SeatField nontransparentDepartmentID={nontransparentDepartmentID}  nontransparentGroupID={nontransparentGroupID} show_review={true}/>
                </SingleFieldList>
            </ReferenceManyField>
        )
    } else {
        columns.push(
            <ReferenceManyField label={currentRotation?.startDate} reference="preferences" target="publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID" filter={{ publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": currentRotation?.id } } }} link={false}>
                <SingleFieldList linkType={false}>
                    <SeatField nontransparentDepartmentID={nontransparentDepartmentID}  nontransparentGroupID={nontransparentGroupID} show_review={true}/>
                </SingleFieldList>
            </ReferenceManyField>)
    }

    const [page, setPage] = useStore('trainee.page', 1);
    const [perPage, setPerPage] = useStore('trainee.perPage', 150);
    const sort = { field: 'name', order: 'ASC' };
    const { data, pageInfo, isLoading } = useGetList('trainees', {
        filter: { traineesByTypeAndTraineeIndex: { type: 'Trainee', filter: filter } } ,
        pagination: { page, perPage },
        sort,
    });

    if (isLoading) { return (<></>) }
    const { hasNextPage, hasPreviousPage } = pageInfo
    var currentCohortID = qualifyingTrainee_currentCohorts[0]?.cohortsIndex
    if (qualifyingTrainee_currentCohorts.length > 2) {
        currentCohortID = ""
    }
    // const { hasNextPage, hasPreviousPage } = pageInfo;
    return (
        <ListContextProvider value={{ data, page, perPage, setPage, setPerPage, hasNextPage, hasPreviousPage, sort }} >
            <Box component="block" >
            <Form onSubmit={onSubmit} defaultValues={{ siteID: siteID, cohortsIndex: currentCohortID, departmentID:nontransparentDepartmentID, groupID: nontransparentGroupID }}>
                <Box display="flex" alignItems="stretch" alignContent='space-between' mb={1} flexDirection={'row-reverse'} >
                <Box component="span" mr={2} >
                            {isLoading ? null : <TraineeExportButton exporter={qualifyingTraineeExporter} params={{currentCohorts,siteID,qualifyingTrainee_currentCohorts,nontransparentDepartmentID,nontransparentGroupID }} size='large' variant="outlined" color="primary" resource="trainees" />}
                        </Box>
                    <Box component="span" mr={2} mb={3.5}>
                            <Button size='large' variant="outlined" color="primary" type="submit">
                                Filter
                            </Button>
                            </Box>
                             
                            <Box display="flex" alignItems="flex-end" mb={1}   >
                                <Box component="span" mr={2} >
                                <SelectInput source="cohortsIndex" label="Cohort" choices={[
                                        { id: '2', name: 'Third Seat' },
                                        { id: '3', name: 'Fourth Seat' }
                                                                            ]} /> 
                                </Box>
                                <Box component="span" mr={2} >
                                    <ReferenceInput source="siteID" reference='sites'  >
                                        <SelectInput label="Site" sx={{ width: 200 }} />
                                    </ReferenceInput>
                                </Box>
                                <Box component="span" mr={2} >
                                    <ReferenceInput source="groupID" reference='groups'  filter={{ groupsByTypeAndName: { type: "Groups" } }} perPage={100}>
                                        <SelectInput label="Group" sx={{ width: 200 }} />
                                    </ReferenceInput>
                                </Box>
                                <Box component="span" mr={2} >
                                    <ReferenceInput source="departmentID" reference='departments'  filter={{ departmentsByTypeAndName: { type: "Department" } }} perPage={1000}>
                                        <SelectInput label="Department" sx={{ width: 200 }} optionText={record => <DepartmentPlusSiteField record={record} />}/>
                                    </ReferenceInput>
                                </Box>
                                
                            </Box>
                </Box>
                </Form>
            </Box>
            <div style={{ marginTop: "20px" }}>
                <Card  >
                    <DataGridWithIndex sort={sort} sx={{
                        '& .column-id': { textAlign: 'left' },
                        '& .column-name': { textAlign: 'left' },
                    }} header={<QualifyingTraineesHeader rotationsList={rotationsList} />}>
                        <WrapperField source="name">
                            <TraineeShowPageLink />
                        </WrapperField>                        
                        <ReferenceField source="cohortID" reference="rotations" label="Cohort" link={false}>
                            <FunctionField label="Cohort" render={(record) => indexToYear(record.cohortsIndex)} />
                        </ReferenceField>
                        {process.env.REACT_APP_MULTISITE == "1" &&
                            <ReferenceField source="siteID" reference="sites" label="Office" link={false}>
                                <TextField source="name" />
                            </ReferenceField>
                        }
                        {columns}
                        {process.env.REACT_APP_DESIRED_GRADUATION_SEAT_ON != "0" && 
                        <ReferenceField label="Desired Qualification Area" source="desiredGraduatingSeatID" reference='departments' link="show" >
                                <SeatChipField nontransparentDepartmentID={nontransparentDepartmentID} nontransparentGroupID={nontransparentGroupID} text_color={"black"}/>
                        </ReferenceField>
                        }
                    </DataGridWithIndex>
                </Card>
                <PostPagination />
            </div>
        </ListContextProvider>
    );
}

const ReminderEmailBar = (props) => {
    const [reminderEmailLoading, setReminderEmailLoading] = React.useState(false)
    const refresh = useRefresh();
    const notify = useNotify();
    const reminderEmail = () => {
        const apiName = 'reminderEmail';
        const path = '/reminderEmail';

        let jwt;
        Auth.currentSession().then(data => {
            // console.log(data)
            // console.log(data.idToken)
            const myInit = {
                headers: {
                    Authorization: data.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            };
            setReminderEmailLoading(true);
            API.get(apiName, path, myInit).then(() => { setReminderEmailLoading(false); refresh() }).catch(error => { setReminderEmailLoading(false); notify(error.response.data, { type: 'error' }); })
        })
    }
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(<Box component="span" mr={2} >
                <Button size='large' variant="outlined" color="primary" onClick={handleClickOpen} >
                    {reminderEmailLoading ? <CircularProgress size="26px" /> : "Reminder email"}
                </Button>
                <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Reminder email"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       Are you sure?
                    </DialogContentText>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={() => { handleClose(); reminderEmail(); }} autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog >
            </Box>
            )
}

const AdminDashboard = (props) => {
    const [isRotationsOverviewAccordionOpen, setIsRotationsOverviewAccordionOpen] = useStore('isRotationsOverviewAccordionOpen', false);
    const [isSeatsOverviewAccordionOpen, setIsSeatsOverviewAccordionOpen] = useStore('isSeatsOverviewAccordionOpen', false);
    const [isDepartmentsOverviewAccordionOpen, setIsDepartmentsOverviewAccordionOpen] = useStore('isDepartmentsOverviewAccordionOpen', false);
    const [isQualifyingTraineesOverviewAccordionOpen, setIsQualifyingTraineesOverviewAccordionOpen] = useStore('isQualifyingTraineesOverviewAccordionOpen', false)
    var numberOfPreferences = []
    for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
        numberOfPreferences.push(<FunctionField label={ordinal_suffix_of(priority + 1) + " Preferences"} render={(record) => get_num_of_preferences(priority + 1, record)} />)
    }
    const { permissions } = usePermissions();

    
    return (
        <Card sx={{ mt: 2 }} >
            {permissions?.includes("SuperAdmins") && <ReminderEmailBar/>}
            <Accordion expanded={isRotationsOverviewAccordionOpen} onChange={() => { setIsRotationsOverviewAccordionOpen(!isRotationsOverviewAccordionOpen) }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography>Rotations Overview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List resource='rotations' actions={null} filter={{ rotationsByTypeAndStartDateAdmin: { type: "Rotation" } }} perPage={100} pagination={false}>
                        <Datagrid bulkActionButtons={false} sx={{
                            '& .column-startDate': { textAlign: 'left' },
                        }} >
                            <FunctionField source="startDate" render={record => formatDate(record.startDate)} label="Rotation Date" />
                            {numberOfPreferences}

                            <NumberField source="numberOfNoPreferences" label="No Preferences" sortable={false} />
                            <NumberField source="numberOfManualOverrides" label="Manual overrides" sortable={false} />
                            {/* <GoToTraineeViewButton /> */}
                        </Datagrid>
                    </List>
                </AccordionDetails>


            </Accordion>
            <Accordion expanded={isSeatsOverviewAccordionOpen} onChange={() => { setIsSeatsOverviewAccordionOpen(!isSeatsOverviewAccordionOpen) }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography>Seats Overview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {isSeatsOverviewAccordionOpen &&
                        <TraineeBySeats />}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={isDepartmentsOverviewAccordionOpen} onChange={() => { setIsDepartmentsOverviewAccordionOpen(!isDepartmentsOverviewAccordionOpen) }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Departments Overview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {isDepartmentsOverviewAccordionOpen &&
                        <DepartmentStatisticsList />
                    }
                </AccordionDetails>
            </Accordion>
            {process.env.REACT_APP_QUALIFYING_TRAINEES_ON == "1" &&
            <Accordion expanded={isQualifyingTraineesOverviewAccordionOpen} onChange={() => { setIsQualifyingTraineesOverviewAccordionOpen(!isQualifyingTraineesOverviewAccordionOpen) }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Qualifying Trainees Overview</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {isQualifyingTraineesOverviewAccordionOpen &&
                        <QualifyingTraineesList />
                    }
                </AccordionDetails>
            </Accordion>
}
        </Card>
    )
}

export const Dashboard = () => {
    const { data, isLoading } = useGetIdentity();
    const { permissions } = usePermissions();
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    );
    if (!isLoading) {
        if (!permissions?.includes("Admins")) {
            if (isSmall) {
                return (
                    <ShowUserProfileMobile {...data} />
                )
            } else {
                return (
                    <ShowUserProfile {...data} />)
            }
        } else {
            return (<AdminDashboard />)
        }
    }
    return (
        <Card></Card>
    )
}