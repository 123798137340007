import * as React from 'react';
import { View, Image, useTheme, Authenticator  } from '@aws-amplify/ui-react';
import { Alert } from '@aws-amplify/ui-react';
import {Auth} from "@aws-amplify/auth";
import logo from '../images/logo.png';
import clientLogo from '../images/dentons.svg'


export const authenticatorComponents = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        {process.env.REACT_APP_SAML != null && process.env.REACT_APP_SAML != "0" &&
          <button onClick={() => Auth.federatedSignIn({customProvider: process.env.REACT_APP_SAML})}>
            Single Sign On
          </button>
          }

        {/* <Image
          alt="Client logo"
          src={clientLogo}
        /> */}
        <Image
          alt="OptiSeat logo"
          src={logo}
        />
      </View>
    );
  },
  ForceNewPassword: {
    FormFields() {
      return (
        <>
          <Authenticator.ForceNewPassword.FormFields />
          <Alert variation="info">Password must be at least 12 characters long and contain at least one lowercase letter, one uppercase letter, one number and one symbol.</Alert >
        </>
      );
    },
  }

}

export const services = {
  handleSignIn(username, password) {
      const user = Auth.signIn(username, password)
      console.log(user)
      if (user) {
        // do this only for specific users. Check E-Mail/Role/Flag or sth. You can also let the user decide by an checkbox in UI
        if (user.challengeName !== 'SOFTWARE_TOKEN_MFA' && process.env.REACT_APP_MFA == "1") {
          user.challengeName = 'MFA_SETUP';
        }
      return user
      }
  },
  async handleSignInConfirmation(totpCode) {
    try {
      if (process.env.REACT_APP_MFA == "1") {
        await Auth.confirmSignIn({ challengeResponse: totpCode });
        Auth.currentAuthenticatedUser().then(user => Auth.setPreferredMFA(user, 'SOFTWARE_TOKEN_MFA'));
      }
    } catch (error) {
      console.log(error);
    }
  },
  async  handleTOTPVerification(totpCode) {
  try {
    await Auth.verifyTOTPSetup({ code: totpCode });
  } catch (error) {
    console.log(error);
  }},
  async handleUpdateMFAPreference() {
  try {
    await Auth.updateMFAPreference({ totp: 'PREFERRED' });
  } catch (error) {
    console.log(error);
  }
}

};

export const formFields = {
  setupTotp: {
    QR: {
      totpIssuer: 'OptiSeat',
      totpUsername: 'Users',
    },
  },
}