import '@aws-amplify/ui-react/styles.css';
import {
    List,
    Datagrid,
    SimpleForm,
    Create,
    Edit,
    EditButton,
    DateField,
    DateInput,
    usePermissions,
    NumberField,
    NumberInput,
    FunctionField,
    SelectInput,
    BooleanInput
} from "react-admin";
import { PostPagination } from './Trainee';


export const RotationsList = (props) => {

    const { permissions } = usePermissions();
    return (
        <List {...props} exporter={false} pagination={<PostPagination />} filter={{ rotationsByTypeAndStartDate: { type: "Rotation" } }}>
            <Datagrid bulkActionButtons={permissions?.includes("Admins") ? true : false} sx={{
                '& .column-startDate': { textAlign: 'left' },
            }} >
                <FunctionField source="startDate" render={record => formatDate(record.startDate)} label="Training Contract Start Date" />
                <FunctionField label="Cohort" render={(record) => indexToYear(record.cohortsIndex)} />
                {permissions?.includes("Admins") && (<EditButton />)}
            </Datagrid>
        </List>
    );
};

export const RotationsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm validate={value => { if (!value.cohortsIndex) { return { cohortsIndex: "Need to select a cohort" }; } else { return {}; } }}>
            <DateInput source="startDate" label="Training Contract Start Date" />
            <DateInput source="endDate" label="Rotation End Date" />
            <SelectInput source="cohortsIndex" label="Current Cohort" choices={[
                { id: '0', name: 'First Seat' },
                { id: '1', name: 'Second Seat' },
                { id: '2', name: 'Third Seat' },
                { id: '3', name: 'Fourth Seat' },
                { id: '8', name: 'Fifth Year' },
                { id: '10', name: 'Sixth Year' },
                { id: '12', name: 'Qualified' }
            ]} />
            <DateInput source="deadline" label="Trainees must submit preferences by:" />
            <BooleanInput source="published"/>
            <BooleanInput source="visible"/>
        </SimpleForm>
    </Edit>
);

export const indexToYear = (cohortsIndex) => {
    if (cohortsIndex == 0) { return "First Seat"; } else if (cohortsIndex == 1) { return "Second Seat"; } else if (cohortsIndex == 2) { return "Third Seat"; } else if (cohortsIndex == 3) { return "Fourth Seat"; } else if (cohortsIndex == 8) { return "Fifth year"; } else if (cohortsIndex == 10) { return "Sixth year"; } else if (cohortsIndex >= 12) { return "Qualified"; }
}

export const RotationsCreate = (props) => (
    <Create {...props} >
        <SimpleForm defaultValues={{ type: "Rotation" }}>
            <DateInput source="startDate" label="Training Contract Start Date" />
            <DateInput source="endDate" label="Rotation End Date" />
            <SelectInput source="cohortsIndex" label="Current Cohort" choices={[
                { id: '0', name: 'First Year' },
                { id: '2', name: 'Second Year' },
                { id: '8', name: 'Fifth Year' },
                { id: '10', name: 'Sixth Year' },
                { id: '12', name: 'Qualified' }
            ]} />
            <DateInput source="deadline" label="Trainees must submit preferences by:" />
            <BooleanInput source="published"/>
            <BooleanInput source="visible"/>
        </SimpleForm>
    </Create>
);

export function formatDate(dateString) {
    const dateObject = new Date(dateString)
    const monthYearString = dateObject.toLocaleDateString("en-US", { month: "long", year: "numeric" })
    return monthYearString;
}